import { GET_CITY, GET_MENU_SUBMENU } from "../action/menu";
import { MenuSubMenu } from "../../datamodels/MenuSubMenu";

const initialState = {
  menuSubMenus: new MenuSubMenu(),
  city: [],
};

const MenuSubMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MENU_SUBMENU: {
      const { data } = action;
      return {
        ...state,
        menuSubMenus: data.map(
          (c) => new MenuSubMenu(c.id, c.name, c.slug_url, c.subMenu),
        ),
      };
    }

    case GET_CITY: {
      const { data } = action;
      return {
        ...state,
        city: data,
      };
    }

    default:
      return state;
  }
};

export default MenuSubMenuReducer;
