export class CartDataModel {
  constructor(
    id = "",
    productId = "",
    qty = 0,
    color = "",
    size = "",
    mrp = 0,
    sell_price = 0,
    product_name = "",
    product_slug = "",
    max_sale_qty = 0,
    min_sale_qty = 0,
    image = "",
    total = 0,
    tax = 0,
    pv = 0,
  ) {
    this.id = id;
    this.product_id = productId;
    this.qty = qty;
    this.color = color;
    this.size = size;
    this.mrp = mrp;
    this.sell_price = sell_price;
    this.product_name = product_name;
    this.product_slug = product_slug;
    this.max_sale_qty = max_sale_qty;
    this.min_sale_qty = min_sale_qty;
    this.product_image = image;
    this.totalPrice = total;
    this.tax = tax;
    this.pv = pv;
  }
}
