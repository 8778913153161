import WebLayout from "../layout/WebLayout";
import { useSelector } from "react-redux";
import { useSearchProductFetch } from "../hooks/useSearchProductFetch";
import SearchProductCard from "../components/SearchProductCard";

function SearchProduct() {
  const { searchProducts } = useSelector((state) => state.product);

  const [isLoader] = useSearchProductFetch();

  return (
    <WebLayout pageName={SearchProduct}>
      <section className=" font-poppins">
        {searchProducts.length > 0 ? (
          <div className="px-4 py-1 mx-auto lg:py-2 md:px-6">
            <div className="flex flex-wrap mb-24 -mx-3">
              <div className="w-full px-3">
                {isLoader ? (
                  <div className={"flex justify-center"}>
                    <i className={"fa fa-spinner fa-spin fa-2x"}></i>
                  </div>
                ) : (
                  <div className="flex-wrap items-center ">
                    <SearchProductCard searchProducts={searchProducts} />
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className={"text-center"}>
            <p className={"text-lg font-bold my-10"}>
              The search you performed did not yield any related data. Please
              try searching for another keyword.
            </p>
          </div>
        )}
      </section>
    </WebLayout>
  );
}

export default SearchProduct;
