import {
  MSME_USER_ID,
  MSME_USER_TOKEN,
  MSME_USER_TYPE,
} from "../../utils/Constants";
import { removeCookie } from "../../utils/CookieAction";

export const logout = () => {
  return async () => {
    await removeCookie(MSME_USER_TOKEN);
    await removeCookie(MSME_USER_TYPE);
    await removeCookie(MSME_USER_ID);
  };
};
