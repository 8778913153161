import toast from "react-hot-toast";

export const toastMessage = (message, type, position, duration) => {
  if (type === "success") {
    toast.success(message, {
      position: position,
      duration: duration,
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
  } else {
    toast.error(message, {
      position: position,
      duration: duration,
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
  }
};
