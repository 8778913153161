import { BASE_URL, MSME_USER_TOKEN } from "../../utils/Constants";
import { getCookie } from "../../utils/CookieAction";
import { logout } from "./logout";

export const GET_CATEGORY_RANDOM_PRODUCTS = "GET_CATEGORY_RANDOM_PRODUCTS";

export const GET_CATEGORY_STATIC_PRODUCTS = "GET_CATEGORY_STATIC_PRODUCTS";

export const GET_PLACES_PRODUCTS = "GET_PLACES_PRODUCTS";

export const GET_PRODUCTS = "GET_PRODUCTS";

export const GET_PRODUCT_DETAILS = "GET_PRODUCT_DETAILS";

export const GET_SELLER_WISE_PRODUCTS = "GET_SELLER_WISE_PRODUCTS";

export const GET_SEARCH_PRODUCTS_NAME = "GET_SEARCH_PRODUCTS_NAME";

export const GET_SEARCH_PRODUCTS = "GET_SEARCH_PRODUCTS";

export const getCategoryProducts = (type = "static", limit = 2, city) => {
  return async (dispatch) => {
    let searchCity = city ? city : "city";
    const url = `${BASE_URL}category-products/${type}/${limit}/${window.btoa(
      searchCity,
    )}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const result = await response.json();
      throw new Error(result.message);
    }

    const result = await response.json();

    if (result.status) {
      dispatch({
        type:
          type === "static"
            ? GET_CATEGORY_STATIC_PRODUCTS
            : GET_CATEGORY_RANDOM_PRODUCTS,
        data: result.data,
      });
    } else {
      throw new Error(result.message);
    }
  };
};

export const getPlacesProducts = (place, limit) => {
  return async (dispatch) => {
    const response = await fetch(
      `${BASE_URL}places-products/${place}/${limit}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (!response.ok) {
      const result = await response.json();
      throw new Error(result.message);
    }

    const result = await response.json();

    if (result.status) {
      dispatch({
        type: GET_PLACES_PRODUCTS,
        data: result.data,
      });
    } else {
      throw new Error(result.message);
    }
  };
};

export const getProducts = (
  page,
  categoryId,
  subCategoryId,
  childCategoryId,
  city,
) => {
  return async (dispatch) => {
    let searchCity = city ? city : "city";

    const response = await fetch(
      `${BASE_URL}products/${categoryId}/${subCategoryId}/${childCategoryId}?page=${page}&city=${window.btoa(
        searchCity,
      )}`,

      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (!response.ok) {
      const result = await response.json();
      throw new Error(result.message);
    }

    const result = await response.json();

    if (result.status) {
      dispatch({
        type: GET_PRODUCTS,
        payload: {
          data: result.data.data,
          currentPage: result.data.current_page,
          lastPage: result.data.last_page,
          from: result.data.from,
          to: result.data.to,
          totalData: result.data.total,
        },
      });
    } else {
      throw new Error(result.message);
    }
  };
};

export const getProductDetails = (slug, id) => {
  return async (dispatch) => {
    const token = getCookie(MSME_USER_TOKEN);
    let parsedToken = "";
    if (token) {
      parsedToken = JSON.parse(token);
    }

    const response = await fetch(
      `${BASE_URL}products-details/${slug}?product_id=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: parsedToken ? `Bearer ${parsedToken}` : [],
        },
      },
    );

    if (!response.ok) {
      const result = await response.json();
      throw new Error(result.message);
    }

    const result = await response.json();

    if (result.msg === "Unauthenticated") {
      dispatch(logout());
    }

    if (result.status) {
      dispatch({
        type: GET_PRODUCT_DETAILS,
        payload: result.data,
      });
    } else {
      throw new Error(result.message);
    }
  };
};

export const getProductDetailsByVariant = (id, size = "", color = "") => {
  return async (dispatch) => {
    const response = await fetch(
      `${BASE_URL}products-details-variant/${id}/${size}/${color}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (!response.ok) {
      const result = await response.json();
      throw new Error(result.message);
    }

    const result = await response.json();

    if (result.status) {
      dispatch({
        type: GET_PRODUCT_DETAILS,
        payload: result.data,
      });
    } else {
      throw new Error(result.message);
    }
  };
};

export const getSellerWiseProducts = (vendor_id) => {
  return async (dispatch) => {
    const response = await fetch(
      `${BASE_URL}seller-wise-product/${vendor_id}`,

      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (!response.ok) {
      const result = await response.json();
      throw new Error(result.message);
    }

    const result = await response.json();

    if (result.status) {
      dispatch({
        type: GET_SELLER_WISE_PRODUCTS,
        payload: {
          data: result.data.product,
          user: result.data.user,
        },
      });
    } else {
      throw new Error(result.message);
    }
  };
};

export const getFilterProducts = (search) => {
  return async (dispatch) => {
    const response = await fetch(`${BASE_URL}filter-products`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        search: search,
      }),
    });

    if (!response.ok) {
      const result = await response.json();
      throw new Error(result.message);
    }

    const result = await response.json();

    if (result.status) {
      dispatch({
        type: GET_SEARCH_PRODUCTS_NAME,
        data: result.data,
      });
    } else {
      throw new Error(result.message);
    }
  };
};

export const getSearchProducts = (city, search) => {
  return async (dispatch) => {
    let searchCity = city ? city : "city";
    const url = `${BASE_URL}search-products/${window.btoa(
      searchCity,
    )}/${search}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const result = await response.json();
      throw new Error(result.message);
    }

    const result = await response.json();

    if (result.status) {
      dispatch({
        type: GET_SEARCH_PRODUCTS,
        data: result.data,
      });
    } else {
      throw new Error(result.message);
    }
  };
};
