import React from "react";
import { FaInstagram, FaTwitter, FaLinkedin, FaYoutube } from "react-icons/fa";
import { popular } from "../data/popularData";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="w-full mx-auto px-6 py-4 text-md bg-gray-50 border-b">
        <p className="text-gray-500">
          www.amsmetrading.com is an online B2B sales and purchase marketing
          portal for the Large and Small ABHHYAM Sector with an aim to provide
          sales orders without any branding cost. The online B2B sales and
          purchase design with a direct selling marketing plan so that the buyer
          on each purchase may get incentives and pension bonds funds for his
          employee.{" "}
        </p>
      </div>
      <div className="w-full mx-auto px-6 py-4 text-md bg-gray-50 border-b">
        <p className="text-gray-800 font-bold text-xl pb-2">Popular Products</p>
        {popular.map((popularPro) => {
          return (
            <span key={popularPro}>
              <a href="#" className="text-gray-500">
                {popularPro}
              </a>
              <span className="text-xs px-1">|</span>
            </span>
          );
        })}
      </div>
      <div className="w-full mx-auto px-6 py-4 text-md bg-gray-50 border-b flex justify-between">
        <div className="flex items-center">
          <p className="text-gray-800 font-bold text-xl">Follow Us:</p>
          <span className="px-2">
            <FaInstagram />
          </span>
          <span className="px-2">
            <FaTwitter />
          </span>
          <span className="px-2">
            <FaLinkedin />
          </span>
          <span className="px-2">
            <FaYoutube />
          </span>
        </div>
        <div>
          <img
            src={require("../assets/images/icon/google_play.png")}
            alt="google_play"
            className="dark:invert"
            width={100}
            height={24}
          />
        </div>
      </div>
      <div className="w-full bg-gray-50 flex md:flex-row flex-col justify-between items-start p-2">
        <div className="p-5 ">
          <ul>
            <p className="text-gray-800 font-bold text-xl pb-4">Suppliers</p>
            <li className="text-gray-500 text-md pb-2 hover:text-blue-600 cursor-pointer">
              Sell on Abhhyam MSME Trading
            </li>
            <li className="text-gray-500 text-md pb-2 hover:text-blue-600 cursor-pointer">
              Latest BuyLead
            </li>
            <li className="text-gray-500 text-md pb-2 hover:text-blue-600 cursor-pointer">
              Learning Centre
            </li>
            <li className="text-gray-500 text-md pb-2 hover:text-blue-600 cursor-pointer">
              Ship With Abhhyam MSME Trading
            </li>
          </ul>
        </div>
        <div className="p-5">
          <ul>
            <p className="text-gray-800 font-bold text-xl pb-4">Buyers</p>
            <li className="text-gray-500 text-md pb-2 hover:text-blue-600 cursor-pointer">
              Post Your Requirement
            </li>
            <li className="text-gray-500 text-md pb-2 hover:text-blue-600 cursor-pointer">
              Products You Buy
            </li>
            <li className="text-gray-500 text-md pb-2 hover:text-blue-600 cursor-pointer">
              Search Products & Suppliers
            </li>
            <li className="text-gray-500 text-md pb-2 hover:text-blue-600 cursor-pointer">
              Pay With Abhhyam MSME Trading
            </li>
          </ul>
        </div>
        <div className="p-5">
          <ul>
            <p className="text-gray-800 font-bold text-xl pb-4">Company</p>
            <li className="text-gray-500 text-md pb-2 hover:text-blue-600 cursor-pointer">
              <Link to="/faq">FAQ,s</Link>
            </li>
            <li className="text-gray-500 text-md pb-2 hover:text-blue-600 cursor-pointer">
              <Link to="/certification">Certification</Link>
            </li>

            <li className="text-gray-500 text-md pb-2 hover:text-blue-600 cursor-pointer">
              <Link
                className="flex items-center pr-2"
                to={"/network-registration"}
                aria-current={"page"}>
                ERM Registration
              </Link>
            </li>
            <li className="text-gray-500 text-md pb-2 hover:text-blue-600 cursor-pointer">
              <Link
                className="flex items-center pr-2"
                to={"/registration"}
                aria-current={"page"}>
                List Your Business
              </Link>
            </li>
          </ul>
        </div>
        <div className="p-5">
          <ul>
            <p className="text-gray-800 font-bold text-xl pb-4">Support</p>
            <li className="text-gray-500 text-md pb-2 hover:text-blue-600 cursor-pointer">
              Help Us
            </li>
            <li className="text-gray-500 text-md pb-2 hover:text-blue-600 cursor-pointer">
              Contact Us
            </li>
            <li className="text-gray-500 text-md pb-2 hover:text-blue-600 cursor-pointer">
              Feedback
            </li>
            <li className="text-gray-500 text-md pb-2 hover:text-blue-600 cursor-pointer">
              Careers
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full flex flex-col justify-center items-center text-center  p-4 bg-gray-50 border-t-2 border-slate-100">
        <h1 className=" text-gray-800 font-semibold">
          © 2023-2024 amsmetrading. All rights reserved
        </h1>
      </div>
    </>
  );
}

export default Footer;
