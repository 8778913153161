import { combineReducers } from "redux";

import BannerReducer from "./reducer/banner";
import CategoryReducer from "./reducer/category";
import ProductReducer from "./reducer/products";
import MenuSubMenuReducer from "./reducer/menu";
import AuthReducer from "./reducer/auth";
import CartReducer from "./reducer/cart";
import CheckoutReducer from "./reducer/checkout";
import FaqReducer from "./reducer/faq";
import BrandReducer from "./reducer/brand";

const appReducer = combineReducers({
  category: CategoryReducer,
  banner: BannerReducer,
  product: ProductReducer,
  menu: MenuSubMenuReducer,
  auth: AuthReducer,
  cart: CartReducer,
  checkout: CheckoutReducer,
  faq: FaqReducer,
  brand: BrandReducer,
});

export const RootReducer = (state, action) => {
  // if (action.type === LOGOUT) {
  //   return appReducer(undefined, action);
  // }

  return appReducer(state, action);
};
