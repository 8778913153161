import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as categoryActions from "../store/action/category";

export const useSubAndChildCategoryFetch = (slug_url) => {
  const dispatch = useDispatch();

  const subAndChildCategoryFetch = useCallback(() => {
    (async () => {
      try {
        await dispatch(categoryActions.getSubAndChildCategory(slug_url));
      } catch (error) {}
    })();
  }, [slug_url, dispatch]);

  useEffect(() => {
    subAndChildCategoryFetch();
  }, [subAndChildCategoryFetch]);

  return [];
};
