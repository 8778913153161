import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as menuSubMenuActions from "../store/action/menu";

export const useMenuSubMenuFetch = () => {
  const dispatch = useDispatch();

  const menuSubMenuFetch = useCallback(() => {
    (async () => {
      try {
        await dispatch(menuSubMenuActions.getMenuSubMenu());
      } catch (error) {}
    })();
  }, [dispatch]);

  useEffect(() => {
    menuSubMenuFetch();
  }, [menuSubMenuFetch]);

  return [];
};
