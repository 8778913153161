import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import * as cartActions from "../../store/action/cart";
import BtnLoader from "../BtnLoader";
import { toastMessage } from "../../utils/Message";

const CartRemove = ({ cartId, qty }) => {
  const [isLoader, setIsLoader] = useState(false);
  const dispatch = useDispatch();

  const removeCart = useCallback(async () => {
    setIsLoader(true);
    try {
      await dispatch(cartActions.cartRemove(cartId, qty));
      await dispatch(cartActions.cartCount());
      setIsLoader(false);
    } catch (e) {
      toastMessage(e.message, "danger", "top-center", 5000);
      setIsLoader(false);
    }
  }, [cartId, dispatch, setIsLoader]);

  return (
    <button
      className="bg-red-500 text-white text-sm rounded-sm p-1  px-2"
      onClick={() => removeCart()}
    >
      {isLoader ? <BtnLoader /> : ""}
      Remove
    </button>
  );
};

export default CartRemove;
