import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as faqActions from "../store/action/faq";

export const useHomeFaqFetch = () => {
  const dispatch = useDispatch();

  const faqFetch = useCallback(() => {
    (async () => {
      try {
        await dispatch(faqActions.getHomeFaq());
      } catch (error) {}
    })();
  }, [dispatch]);

  useEffect(() => {
    faqFetch();
  }, [faqFetch]);

  return [];
};
