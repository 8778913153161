import * as cartActions from "../../store/action/cart";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import BtnLoader from "../BtnLoader";
import { toastMessage } from "../../utils/Message";

const PrimaryCartButton = ({ cartQty, minQty, maxQty, cartId, productId }) => {
  const [isLoader, setIsLoader] = useState(false);
  const dispatch = useDispatch();
  const [productQty, setProductQty] = useState(0);
  const [tempQty, setTempQty] = useState(0);
  
  const addCount = async () => {
    setProductQty(Number(cartQty) + Number(minQty));
    await cartUpdate(Number(cartQty) + Number(minQty), "add");
  };
  const minusCount = async () => {
    setProductQty(Number(cartQty) - Number(minQty));
    await cartUpdate(Number(cartQty) - Number(minQty), "minus");
  };

  const cartUpdate = useCallback(
    async (qty, type) => {
      setIsLoader(true);
      try {
        await dispatch(cartActions.cartUpdate(cartId, productId, qty, type));
        await dispatch(cartActions.cartCount());
        setIsLoader(false);
      } catch (e) {
        toastMessage(e.message, "danger", "top-center", 5000);
        setIsLoader(false);
      }
    },
    [cartId, dispatch, productId],
  );

  useEffect(() => {
    setProductQty(cartQty);
    setTempQty(cartQty);
  }, [cartQty]);

  const handleBlur = async () => {
    const stateQty = Number(tempQty);

    if (stateQty === 0) {
      setProductQty(productQty);
      setTempQty(productQty);
      return false;
    }

    if (stateQty % minQty === 0) {
      setProductQty(stateQty);
      setTempQty(stateQty);
      await cartUpdate(stateQty, "bulk");
    } else {
      let storeQty = Math.round(stateQty / minQty) * minQty;
      if (storeQty > 0 && stateQty >= minQty) {
        setProductQty(storeQty);
        setTempQty(storeQty);
        await cartUpdate(storeQty, "bulk");
      } else {
        setProductQty(productQty);
        setTempQty(productQty);
        await cartUpdate(productQty, "bulk");
      }
    }
  };

  // Temporary state

  const handleChanges = (event) => {
    // Update temporary value while typing
    setTempQty(event.target.value);
  };

  return (
    <div className="flex flex-row justify-between pr-10">
      <div className="flex">
        <button
          onClick={minusCount}
          className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 cursor-pointer border border-gray-300 border-r-0 w-7 h-7 flex items-center justify-center pb-1 rounded-full shadow-sm"
          disabled={isLoader}
        >
          -
        </button>
        {isLoader ? (
          <div className="border border-gray-300 h-7 text-center w-12">
            <BtnLoader />
          </div>
        ) : (
          <input
            id="counter"
            aria-label="input"
            className="border border-gray-300 h-7 text-center w-14 mr-2 ml-2"
            type="text"
            value={tempQty}
            min={minQty}
            onChange={handleChanges}
            onBlur={handleBlur}
          />
        )}
        <button
          onClick={addCount}
          className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 cursor-pointer border border-gray-300 border-l-0 w-7 h-7 flex items-center justify-center pb-1 rounded-full shadow-sm"
          disabled={isLoader}
        >
          +
        </button>
      </div>
    </div>
  );
};

export default PrimaryCartButton;
