import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as categoryActions from "../store/action/category";

export const useSubCategoryFetch = (id) => {
  const dispatch = useDispatch();

  const subCategoryFetch = useCallback(() => {
    (async () => {
      try {
        await dispatch(categoryActions.getSubCategory(id));
      } catch (error) {}
    })();
  }, [id, dispatch]);

  useEffect(() => {
    subCategoryFetch();
  }, [subCategoryFetch]);

  return [];
};
