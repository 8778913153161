import WebLayout from "../layout/WebLayout";

import { useState } from "react";
import * as authActions from "../store/action/auth";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { toastMessage } from "../utils/Message";

function NetworkRegistration() {
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [referBy, setReferBy] = useState("ERM1");
  const [check, setCheck] = useState("Default");
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoader(true);
    try {
      await dispatch(
        authActions.networkRegistration(
          name,
          mobile,
          email,
          password,
          address,
          referBy,
        ),
      );
      setIsLoader(false);
      toastMessage("Register successfully", "success", "top-center", 2000);
      setName("");
      setMobile("");
      setEmail("");
      setPassword("");
      setAddress("");
      setReferBy("");
    } catch (e) {
      toastMessage(e.message, "danger", "top-center", 5000);
      setIsLoader(false);
    }
  };

  return (
    <WebLayout pageName={NetworkRegistration}>
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 sm:grid-cols-6 gap-4">
          <div className="col-start-1 sm:col-start-2 col-span-4">
            <h2 className="text-2xl font-bold text-center mt-5">
              Entity Representative Member - (ERM) Registration
            </h2>
            <div className="py-4">
              <form onSubmit={handleSubmit} id="regForm">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                  <label className="block mb-1">
                    <span className="text-gray-700">
                      Name
                      <span className="font-extrabold text-red-600">*</span>
                    </span>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="mt-1 block w-full"
                      placeholder="Enter Name"
                      required
                    />
                  </label>
                  <label className="block mb-1">
                    <span className="text-gray-700">
                      Mobile No.
                      <span className="font-extrabold text-red-600">*</span>
                    </span>
                    <input
                      type="text"
                      value={mobile}
                      onChange={(e) =>
                        setMobile(
                          e.target.value
                            .replace(/[^0-9.]/g, "")
                            .replace(/(\..*?)\..*/g, "$1"),
                        )
                      }
                      className="mt-1 block w-full"
                      placeholder="Enter mobile No."
                      required
                      maxLength={10}
                    />
                  </label>
                  <label className="block mb-1">
                    <span className="text-gray-700">
                      Email Id
                      <span className="font-extrabold text-red-600">*</span>
                    </span>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="mt-1 block w-full"
                      placeholder="Enter Email Id"
                      required
                    />
                  </label>
                  <label className="block mb-1">
                    <span className="text-gray-700">
                      Password
                      <span className="font-extrabold text-red-600">*</span>
                    </span>
                    <div className="relative rounded-md shadow-sm">
                      <input
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="mt-1 block w-full"
                        placeholder="Enter Password"
                        required
                      />
                      <div class="absolute inset-y-0 right-2 flex items-center">
                        <i
                          onClick={() => setShowPassword(!showPassword)}
                          className={`bx ${
                            showPassword ? "fa fa-eye" : "fa fa-eye-slash"
                          }`}
                          style={{ color: "#555" }}
                        ></i>
                      </div>
                    </div>
                  </label>
                </div>
                <label>
                  Do You Have Reference Id?{" "}
                  <input
                    style={{ marginRight: "10px", marginLeft: "10px" }}
                    type="radio"
                    value=""
                    checked={check === ""}
                    onChange={() => {
                      setCheck("");
                      setReferBy("");
                    }}
                  />
                  Yes
                  <input
                    style={{ marginRight: "10px", marginLeft: "10px" }}
                    type="radio"
                    value="Default"
                    checked={check === "Default"}
                    onChange={() => {
                      setCheck("Default");
                      setReferBy("ERM1");
                    }}
                  />
                  No
                </label>
                <div>
                  <label></label>
                  <label></label>
                </div>
                <div className="grid grid-cols-1 gap-1">
                  <label className="block mb-1">
                    <span className="text-gray-700">Referral Code </span>
                    <input
                      readOnly={check === "Default"}
                      type="text"
                      value={referBy}
                      onChange={(e) => setReferBy(e.target.value)}
                      className="mt-1 block w-full"
                      placeholder="Enter Referral Code"
                      style={{
                        backgroundColor:
                          check === "Default" ? "whitesmoke" : "white",
                      }}
                    />
                  </label>
                </div>
                <div className="grid grid-cols-1 gap-1">
                  <label className="block mb-1">
                    <span className="text-gray-700">
                      Address
                      <span className="font-extrabold text-lg text-red-600">
                        *
                      </span>
                    </span>
                    <textarea
                      name="address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      className="mt-1 block w-full"
                      rows="3"
                      placeholder="Enter your address"
                      required
                    ></textarea>
                  </label>
                </div>

                <button
                  type="submit"
                  className="bg-indigo-500 text-white p-2 mt-4"
                >
                  {isLoader && <i className={"fa fa-spinner fa-spin"}></i>}
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </WebLayout>
  );
}

export default NetworkRegistration;
