import { FaRupeeSign } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import PrimaryCartButton from "../components/button/PrimaryCartButton";
import { useCartFetch } from "../hooks/useCartFetch";
import { useSelector } from "react-redux";
import CartRemove from "../components/button/CartRemove";
import {
  defaultProductImage,
  MSME_USER_ORDER_DETAILS,
} from "../utils/Constants";

function ViewCart() {
  const navigate = useNavigate();
  const { cartList, deliveryAmount, minimumOrderAmount } = useSelector(
    (state) => state.cart,
  );

  const { storeCity } = useSelector((state) => state.auth);

  useCartFetch(storeCity);
  let cartTax = 0;
  let cartTotalPrice = 0;
  let cartTotalQty = 0;
  let pv = 0;

  const placeOrder = () => {
    localStorage.setItem(
      MSME_USER_ORDER_DETAILS,
      JSON.stringify({
        cartTotalPrice,
        cartTotalQty,
        cartTax,
        deliveryAmount,
        pv,
      }),
    );
    navigate("/checkout", { replace: true });
  };

  return (
    <div className="w-full">
      {cartList.length > 0 ? (
        <div className="">
          <h1 className="text-lg font-medium border-b pb-2 flex justify-between">
            View Cart Items
            <p className={"font-bold text-red-500"}>
              Minimum Order Amount : Rs. {minimumOrderAmount}
            </p>
          </h1>
          <div className="col-span-1 p-2 bg-white">
            <div className={""}>
              {cartList.length > 0 &&
                cartList.map((item) => {
                  cartTotalPrice += item.sell_price * item.qty;
                  cartTotalQty += item.qty;
                  cartTax += item.tax * item.qty;
                  pv += item.pv * item.qty;
                  return (
                    <div
                      key={item.id}
                      className="flex justify-between items-center mt-2"
                    >
                      <div className="flex  mb-2">
                        <img
                          src={item.product_image}
                          className="rounded-sm aspect-[6/6] h-10 w-auto"
                          alt="img"
                          onError={(event) => {
                            event.target.src = defaultProductImage;
                            event.onerror = null;
                          }}
                        />

                        <div className="flex flex-col ml-3">
                          <span className="md:text-md font-medium">
                            {item.product_name}
                          </span>
                          <div className="flex">
                            <FaRupeeSign />
                            <span className="text-md font-medium">
                              {item.totalPrice?.toFixed(2)}
                            </span>
                          </div>
                          {item.color ? (
                            <span className="text-xs font-light text-gray-400">
                              Color : {item.color}
                            </span>
                          ) : (
                            ""
                          )}

                          {item.size ? (
                            <span className="text-xs font-light text-gray-400">
                              Size : {item.size}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="flex justify-center items-center">
                        <PrimaryCartButton
                          cartQty={item.qty}
                          minQty={item.min_sale_qty}
                          maxQty={item.max_sale_qty}
                          cartId={item.id}
                          productId={item.product_id}
                        />
                        <div className="text-center mt-2">
                          <CartRemove cartId={item.id} qty={item.qty} />
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="col-span-1 p-1">
            <footer className="fixed bottom-0 w-full right-0 bg-white shadow-md">
              <div className="flex justify-between pt-2">
                <button
                  className="bg-zinc-500 rounded-sm text-white p-2 font-bold w-full flex justify-between px-4 items-center"
                  style={{ borderRadius: 0 }}
                >
                  Total Quantity : <span>{cartTotalQty}</span>
                </button>
                <button
                  className="bg-red-500 rounded-sm text-white p-2 font-bold w-full flex justify-between px-4 items-center"
                  style={{ borderRadius: 0 }}
                >
                  PV : <span>{pv?.toFixed(2)}</span>
                </button>
                <button
                  className="bg-violet-600 rounded-sm text-white p-2 font-bold w-full flex items-center justify-between"
                  style={{ borderRadius: 0 }}
                >
                  Price :
                  <div className={"flex items-center justify-between"}>
                    <FaRupeeSign />
                    {(cartTotalPrice - cartTax)?.toFixed(2)}
                  </div>
                </button>

                <button
                  className="bg-yellow-600 rounded-sm text-white p-2 font-bold w-full flex items-center justify-between"
                  style={{ borderRadius: 0 }}
                >
                  Tax :
                  <div className={"flex items-center justify-between"}>
                    <FaRupeeSign />
                    {cartTax?.toFixed(2)}
                  </div>
                </button>

                <button
                  className="bg-orange-500 rounded-sm text-white p-2 font-bold w-full flex items-center justify-between"
                  style={{ borderRadius: 0 }}
                >
                  Delivery Charges :
                  <div className={"flex items-center justify-between"}>
                    <FaRupeeSign />
                    {deliveryAmount?.toFixed(2)}
                  </div>
                </button>

                <button
                  className="bg-pink-700 rounded-sm text-white p-2 font-bold w-full flex items-center justify-between"
                  style={{ borderRadius: 0 }}
                >
                  Grand Total :
                  <div className={"flex items-center justify-between"}>
                    <FaRupeeSign />
                    {(cartTotalPrice + deliveryAmount)?.toFixed(2)}
                  </div>
                </button>
              </div>
              <button
                className="bg-green-600 rounded-sm text-white p-1 w-full h-10"
                onClick={placeOrder}
              >
                Place Order
              </button>
            </footer>
          </div>
        </div>
      ) : (
        <div className={"text-center"}>
          <div className="p-2 items-center justify-center flex">
            <img
              src={require("../assets/images/cart_empty.png")}
              alt=""
              width={200}
            />
          </div>
          <h1 className={"font-bold text-2xl"}>Your cart is empty</h1>
          <h3 className={"font-semibold text-gray-500"}>
            Looks like you haven't added any product to your cart yet.
          </h3>
        </div>
      )}
    </div>
  );
}

export default ViewCart;
