import SellerProductCard from "../components/SellerProductCard";
import WebLayout from "../layout/WebLayout";
import { useSellerProductFetch } from "../hooks/useSellerProductFetch";
import { useSelector } from "react-redux";
import ViewCart from "../components/ViewCart";

function SellerWiseProduct() {
  const { sellerProductsList, cartSeller } = useSelector(
    (state) => state.product,
  );

  const [isLoader] = useSellerProductFetch();

  return (
    <WebLayout pageName={SellerWiseProduct}>
      <section className=" font-poppins">
        <div className="px-4 py-1 mx-auto lg:py-2 md:px-6">
          <div className="flex flex-wrap mb-24 -mx-3">
            <div className="w-full px-3 lg:w-4/6">
              <div className="px-3 mb-4" style={{ display: "none" }}>
                <div className="items-center justify-between hidden px-3 py-2 bg-gray-100 md:flex  ">
                  <div className="flex">
                    <div className="flex items-center pl-3">
                      <p className="text-xs text-gray-400">Show</p>
                      <div className="px-2 py-2 text-xs text-gray-500 ">
                        <select className="block text-base bg-gray-100 cursor-pointer w-11 dark:text-gray-400 ">
                          <option value="">15</option>
                          <option value="">30</option>
                          <option value="">50</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="pr-3 border-r border-gray-300">
                      <select className="block w-40 text-base bg-gray-100 cursor-pointer dark:text-gray-400 ">
                        <option value="">Low to High Price</option>
                        <option value="">High to Low Price</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {isLoader ? (
                <div className={"flex justify-center"}>
                  <i className={"fa fa-spinner fa-spin fa-2x"}></i>
                </div>
              ) : (
                <div className="flex flex-wrap items-center ">
                  <h1 className={"font-bold text-lg"}>
                    {cartSeller?.name} ({cartSeller?.accountId})
                  </h1>
                  {sellerProductsList.length > 0 ? (
                    <SellerProductCard
                      sellerProductsList={sellerProductsList}
                    />
                  ) : null}
                </div>
              )}
            </div>
            <div className="w-full pr-2 lg:w-2/6 lg:block">
              <ViewCart />
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default SellerWiseProduct;
