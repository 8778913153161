import { CHECKOUT } from "../action/checkout";

const initialState = {};

const CheckoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECKOUT: {
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

export default CheckoutReducer;
