import { Routes, Route } from "react-router-dom";
import About from "../pages/About";
import Home from "../pages/Home";
import Contact from "../pages/Contact";
import CategoryPage from "../pages/Category";
import ProductPage from "../pages/Product";
import ProductDetails from "../pages/ProductDetails";
import Cart from "../pages/Cart";
import Checkout from "../pages/Checkout";
import Registration from "../pages/Registration";
import Payment from "../pages/Payment";
import NetworkRegistration from "../pages/NetworkRegistration";
import RegWelcome from "../pages/RegWelcome";
import BuyerRegistration from "../pages/BuyerRegistration";
import Certification from "../pages/Certification";
import Faq from "../pages/Faq";
import SellerWiseProduct from "../pages/SellerWiseProduct";
import SearchProduct from "../pages/SearchProduct";

function PageRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route
        path="/category/:categorySlug?/:subCategorySlug?/:childCategorySlug?"
        element={<CategoryPage />}
      />
      <Route path="/product" element={<ProductPage />} />
      <Route path="/product-details/:slug" element={<ProductDetails />} />
      <Route path="/registration" element={<Registration />} />
      <Route path="/welcome" element={<RegWelcome />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/payment" element={<Payment />} />
      <Route path={"/network-registration"} element={<NetworkRegistration />} />
      <Route path={"/buyer-registration"} element={<BuyerRegistration />} />
      <Route path="/certification" element={<Certification />} />
      <Route path="/faq" element={<Faq />} />
      <Route
        path={"/seller-product-list/:vendor_id"}
        element={<SellerWiseProduct />}
      />
      <Route path={"/search-product/:city/:name"} element={<SearchProduct />} />
    </Routes>
  );
}

export default PageRoutes;
