import { useDispatch } from "react-redux";
import WebLayout from "../layout/WebLayout";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { cleanExistAnce } from "../store/action/auth";
import * as authActions from "../store/action/auth";
import BtnLoader from "../components/BtnLoader";

function BuyerRegistration() {
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [referBy, setReferBy] = useState("");
  const page = useNavigate();

  const RegisterSubmit = async () => {
    setIsLoader(true);
    try {
      await dispatch(
        authActions.register(mobileNumber, name, referBy, password),
      );
      toast.success("Register Successfully");
      page("/welcome");

      setName("");
      setMobileNumber("");
      setPassword("");
      setReferBy("");
      dispatch(cleanExistAnce());
      setIsLoader(false);
    } catch (error) {
      toast.error(error.message);
      setIsLoader(false);
    }
  };
  return (
    <WebLayout pageName={BuyerRegistration}>
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-6 gap-8">
          <div className="col-start-3 col-span-2">
            <h2 className="text-2xl font-bold text-center mt-5">
              Buyer Registration
            </h2>
            <div className="py-4">
              <form id="regForm">
                <div className="grid grid-cols-1 gap-2">
                  <label className="block mb-1">
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="placeholder:text-black block bg-white h-12 border border-slate-300 rounded-md py-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-md w-full"
                      placeholder="Enter Name"
                    />
                  </label>

                  <label className="relative block mb-1">
                    <span className="text-gray-700 sr-only">Mobile Number</span>
                    <span className="absolute inset-y-0 left-1 flex items-center pr-3 border-r">
                      <p className="h-6 w-6 fill-slate-300">+91</p>
                    </span>
                    <input
                      type="text"
                      name="mobile"
                      className={`placeholder:text-black block h-12 border border-slate-300 rounded-md py-2 pl-12 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-md w-full`}
                      placeholder="Mobile Number"
                      required
                      maxLength={10}
                      value={mobileNumber}
                      onChange={(e) => setMobileNumber(e.target.value)}
                    />
                  </label>

                  <label className="relative block mb-1 text-left">
                    <input
                      type="password"
                      name="password"
                      className="placeholder:text-black block bg-white h-12 border border-slate-300 rounded-md py-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-md w-full"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </label>

                  <label className="block mb-1 text-left">
                    <input
                      type="text"
                      value={referBy}
                      onChange={(e) => setReferBy(e.target.value)}
                      className="placeholder:text-black block bg-white h-12 border border-slate-300 rounded-md py-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-md w-full"
                      placeholder="Enter Referral Code (Optional)"
                    />
                  </label>
                </div>

                <button
                  disabled={isLoader}
                  onClick={RegisterSubmit}
                  className="text-white text-lg w-full px-4 py-3 bg-indigo-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-500 dark:focus:ring-blue-600 inline-flex items-center justify-center mt-3"
                >
                  {isLoader ? <BtnLoader /> : ""}
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </WebLayout>
  );
}

export default BuyerRegistration;
