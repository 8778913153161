import ProductCard from "../components/ProductCard";
import WebLayout from "../layout/WebLayout";
import { productData } from "../data/dummyData";

function ProductPage() {
  return (
    <WebLayout pageName={ProductPage}>
      <ProductCard productList={productData} />
    </WebLayout>
  );
}

export default ProductPage;
