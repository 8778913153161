import { useNavigate } from "react-router-dom";
import { defaultCategoryImage } from "../utils/Constants";
import { categoryCarousel } from "../data/dummyData";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ProductCard from "./ProductCard";
import { useProductFetch } from "../hooks/useProductFetch";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useCategoryFetch } from "../hooks/useCategoryFetch";
import { useSubAndChildCategoryFetch } from "../hooks/useSubAndChildCategoryFetch";

function CategoryCard({ categorySlug, subCategorySlug, childCategorySlug }) {
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const { subAndChildCategories } = useSelector((state) => state.category);
  const { products, currentPage, lastPage, from, to, totalData } = useSelector(
    (state) => state.product,
  );
  const { storeCity } = useSelector((state) => state.auth);

  const [isLoader] = useProductFetch(
    page,
    categorySlug,
    subCategorySlug,
    childCategorySlug,
    storeCity,
  );
  const handlePageNavigate = (subUrl) => {
    setPage(1);
    navigate(`/category/${subUrl}`);
  };

  const handleSubCatPageNavigate = (subUrl) => {
    setPage(1);
    navigate(`/category/${categorySlug}/${subUrl}`);
  };

  const handleChildCatPageNavigate = (subUrl, childUrl) => {
    setPage(1);
    navigate(`/category/${categorySlug}/${subUrl}/${childUrl}`);
  };

  useCategoryFetch();
  useSubAndChildCategoryFetch(categorySlug);

  return (
    <section className="py-4 font-poppins">
      <div className="px-4 py-4 mx-auto lg:py-6 md:px-6">
        <div className="flex flex-wrap mb-24 -mx-3">
          <div className="w-full pr-2 lg:w-1/6 lg:block">
            <div className="p-4 mb-5 bg-white border border-gray-200 dark:border-gray-900 ">
              <h3 className="text-lg font-bold dark:text-gray-400">
                Categories
              </h3>
              <div className="w-16 pb-2 mb-2 border-b border-rose-600 dark:border-gray-400"></div>
              <ul>
                {subAndChildCategories &&
                  subAndChildCategories.map((sub, index) => (
                    <li
                      className="mb-4 text-md capitalize font-bold cursor-pointer"
                      key={index}
                    >
                      <div
                        onClick={() => handleSubCatPageNavigate(sub.slug_url)}
                      >
                        {sub.name}
                      </div>

                      <ul className="pl-3 mt-1">
                        {sub.childCategory &&
                          sub.childCategory.map((child, index) => (
                            <li
                              className="mb-1 text-sm font-light"
                              key={index}
                              onClick={() =>
                                handleChildCatPageNavigate(
                                  sub.slug_url,
                                  child.slug_url,
                                )
                              }
                            >
                              {child.name}
                            </li>
                          ))}
                      </ul>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div className="w-full px-3 lg:w-5/6">
            <div className="px-3 mb-4" style={{ display: "none" }}>
              <div className="items-center justify-between hidden px-3 py-2 bg-gray-100 md:flex  ">
                <div className="flex">
                  <div className="flex items-center pl-3">
                    <p className="text-xs text-gray-400">Show</p>
                    <div className="px-2 py-2 text-xs text-gray-500 ">
                      <select className="block text-base bg-gray-100 cursor-pointer w-11 dark:text-gray-400 ">
                        <option value="">15</option>
                        <option value="">30</option>
                        <option value="">50</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="pr-3 border-r border-gray-300">
                    <select className="block w-40 text-base bg-gray-100 cursor-pointer dark:text-gray-400 ">
                      <option value="">Low to High Price</option>
                      <option value="">High to Low Price</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {isLoader ? (
              <div className={"flex justify-center"}>
                <i className={"fa fa-spinner fa-spin fa-2x"}></i>
              </div>
            ) : (
              <div className="flex flex-wrap items-center ">
                {products.length > 0 ? (
                  <ProductCard
                    productList={products.length > 0 ? [...products] : []}
                    currentPage={currentPage}
                    lastPage={lastPage}
                    from={from}
                    to={to}
                    totalData={totalData}
                    page={page}
                    setPage={setPage}
                  />
                ) : (
                  <div className={"items-center justify-center"}>
                    <h3 className={"font-bold"}>
                      No Product Available at this moment
                    </h3>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default CategoryCard;
