import WebLayout from "../layout/WebLayout";


function Contact() {
    return (
      <WebLayout pageName={Contact}>
          
      </WebLayout>
    )
}

export default Contact;