import Carousel from "react-multi-carousel";
import { offerResponsive } from "../../data/dummyData";
import { Rating } from "../Rating";

const Reviews = () => {
  const reviews = [
    {
      name: "Rajan",
      designation: "Web Developer",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industries standard dummy text ever since the 1500s.",
    },
    {
      name: "Raghvendra Singh",
      designation: "Web Developer",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industries standard dummy text ever since the 1500s.",
    },
    {
      name: "Shashi Bhushan",
      designation: "Web Developer",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industries standard dummy text ever since the 1500s.",
    },
    {
      name: "Lovelesh Singh",
      designation: "Web Developer",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industries standard dummy text ever since the 1500s.",
    },
    {
      name: "Hitesh Rawat",
      designation: "Web Developer",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industries standard dummy text ever since the 1500s.",
    },
    {
      name: "Arjun Sah",
      designation: "Web Developer",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industries standard dummy text ever since the 1500s.",
    },
  ];

  return (
    <div className="bg-white">
      <div className="mx-auto px-4">
        <div className="grid grid-cols-1 gap-2 rounded-lg px-5 mb-4">
          <div className="col-span-3">
            <div className="grid grid-cols-1 gap-2">
              <div className={"flex justify-between items-center"}>
                <h3 className="text-lg font-bold uppercase">
                  CUSTOMER REVIEWS
                </h3>
              </div>
              <Carousel
                swipeable={false}
                draggable={false}
                showDots={false}
                responsive={offerResponsive}
                ssr={true}
                autoPlay={false}
                autoPlaySpeed={5000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerclassName="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                deviceType={"desktop"}
                dotListclassName="custom-dot-list-style"
                itemclassName="carousel-item-padding-40-px"
              >
                {reviews.map((r) => {
                  return (
                    <div className="col-span-12 sm:col-span-6 md:col-span-3 border border-zinc-300 rounded-lg p-3 m-1">
                      <card className="w-full flex flex-col">
                        <div className="flex flex-row mt-2 gap-2">
                          <a href="javascript:void(0);">
                            <img
                              src={require("../../assets/images/icon/user.png")}
                              width={24}
                              height={24}
                              className="rounded-full max-h-10 max-w-10"
                              alt="img"
                            />
                          </a>
                          <div className="flex flex-col">
                            <a href="#">
                              <p className="text-gray-800 text-sm font-semibold">
                                {r.name}
                              </p>
                            </a>
                            <a
                              className="text-gray-400 text-xs mt-1 hover:text-gray-500"
                              href="#"
                            >
                              {r.designation}
                            </a>
                          </div>
                        </div>
                        <Rating />
                        <p className="text-gray-800 text-sm mt-2">
                          {r.description}
                        </p>
                      </card>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
