import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as productActions from "../store/action/product";

export const useProductDetailsFetch = (slug, id, size, color, type = "") => {
  const dispatch = useDispatch();
  const [productLoader, setProductLoader] = useState(false);

  const productDetailsFetch = useCallback(() => {
    (async () => {
      setProductLoader(true);
      try {
        if (type === "variants") {
          await dispatch(
            productActions.getProductDetailsByVariant(id, size, color),
          );
        } else {
          await dispatch(productActions.getProductDetails(slug, id));
        }
        setProductLoader(false);
      } catch (error) {
        setProductLoader(false);
      }
    })();
  }, [type, dispatch, slug, id, size, color]);

  useEffect(() => {
    productDetailsFetch();
  }, [productDetailsFetch]);

  return [productLoader];
};
