const Cookies = require("js-cookie");

export const storeCookie = (name, value) => {
  Cookies.set(name, value, {
    domain: ".amsmetrading.com",
    expires: 7,
    path: "/",
  });
};

export const getCookie = (name) => {
  return Cookies.get(name);
};

export const removeCookie = (name) => {
  return Cookies.remove(name);
};
