import { useState } from "react";
import WebLayout from "../layout/WebLayout";
import { AOA, COI, MOA, PAN } from "../utils/Constants";

function Certification() {
  return (
    <WebLayout pageName={"Certification"}>
      <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">
        <h3 className="text-center text-2xl mb-5">Company Certification</h3>
        <div className="grid sm:grid-cols-2 grid-cols-2 sm:gap-8 gap-4">
          <div className="shadow-md px-4 py-2 rounded-lg">
            <a href="#" className="flex items-center justify-between">
              <p className="font-normal text-base leading-6 text-gray-600 ">
                ABHHYAM MARKETING INDIA PRIVATE LIMITED (AOA)
              </p>
            </a>
            <div>
              <hr className="mt-1" />
              <iframe
                className="mt-1"
                src={AOA}
                height="300"
                width="500"
                title="AOA"></iframe>
            </div>
          </div>

          <div className="shadow-md px-4 py-2 rounded-lg">
            <a href="#" className="flex items-center justify-between">
              <p className="font-normal text-base leading-6 text-gray-600 ">
                ABHHYAM MARKETING INDIA PRIVATE LIMITED (MOA)
              </p>
            </a>

            <div>
              <hr className="mt-1" />
              <iframe
                className="mt-1"
                src={MOA}
                height="300"
                width="500"
                title="AOA"></iframe>
            </div>
          </div>

          <div className="shadow-md px-4 py-2 rounded-lg">
            <a href="#" className="flex items-center justify-between">
              <p className="font-normal text-base leading-6 text-gray-600 ">
                ABHHYAM MARKETING INDIA PRIVATE LIMITED (PAN)
              </p>
            </a>

            <div>
              <hr className="mt-1" />
              <iframe
                className="mt-1"
                src={PAN}
                height="300"
                width="500"
                title="AOA"></iframe>
            </div>
          </div>

          <div className="shadow-md px-4 py-2 rounded-lg">
            <a href="#" className="flex items-center justify-between">
              <p className="font-normal text-base leading-6 text-gray-600 ">
                CERTIFICATE OF INCORPORATION
              </p>
            </a>

            <div>
              <hr className="mt-1" />
              <iframe
                className="mt-1"
                src={COI}
                height="300"
                width="500"
                title="AOA"></iframe>
            </div>
          </div>
        </div>
      </div>
    </WebLayout>
  );
}

export default Certification;
