import WebLayout from "../layout/WebLayout";
import { FaRupeeSign } from "react-icons/fa";
import { useEffect, useState } from "react";
import { MSME_USER_ADDRESS, MSME_USER_ORDER_DETAILS } from "../utils/Constants";
import { useNavigate } from "react-router-dom";
import * as checkoutActions from "../store/action/checkout";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { toastMessage } from "../utils/Message";

function Payment() {
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);
  const navigate = useNavigate();

  const [price, setPrice] = useState(0);
  const [tax, setTax] = useState(0);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [landmark, setLandmark] = useState("");
  const [address, setAddress] = useState("");
  const [paymentReceipt, setPaymentReceipt] = useState("");

  useEffect(() => {
    const data = localStorage.getItem(MSME_USER_ORDER_DETAILS);
    const addressData = localStorage.getItem(MSME_USER_ADDRESS);
    if (data) {
      const parsedData = JSON.parse(data);
      const parsedAddress = JSON.parse(addressData);
      setPrice(parsedData.cartTotalPrice);
      setTax(parsedData.cartTax);
      setName(parsedAddress.name);
      setEmail(parsedAddress.email);
      setPhone(parsedAddress.phone);
      setState(parsedAddress.state);
      setCity(parsedAddress.city);
      setPinCode(parsedAddress.pinCode);
      setLandmark(parsedAddress.landmark);
      setAddress(parsedAddress.address);
    } else {
      navigate("/");
    }
  }, [navigate]);

  const onSubmitFile = (e) => {
    setPaymentReceipt(e.target.files[0]);
  };

  const paymentDone = async (e) => {
    e.preventDefault();
    setIsLoader(true);
    try {
      await dispatch(
        checkoutActions.createOrder(
          paymentReceipt,
          name,
          email,
          phone,
          state,
          city,
          address,
          landmark,
          pinCode,
        ),
      );
      setIsLoader(false);
      toastMessage("Order Created Successfully", "success", "top-center", 2000);
      localStorage.removeItem(MSME_USER_ORDER_DETAILS);
      localStorage.removeItem(MSME_USER_ADDRESS);
      navigate("/", { replace: true });
    } catch (e) {
      toastMessage(e.message, "danger", "top-center", 5000);
      setIsLoader(false);
    }
  };

  return (
    <WebLayout pageName={Payment}>
      <div className="bg-gray-100">
        <div className="md:flex ">
          <div className="w-full p-4 px-4 py-4">
            <div className="md:grid md:grid-cols-3 gap-2 ">
              <div className="col-span-2 p-2 bg-white shadow-md">
                <div className="flex w-full  flex-col justify-start items-start">
                  <div className="mt-2">
                    <p className="text-xl font-semibold leading-5 text-gray-800">
                      Checkout Details
                    </p>
                  </div>
                  <div className="max-w-sm w-full lg:max-w-full lg:flex mt-3">
                    <div className="w-full border-r border-b border-l border-gray-400 lg:border-l lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
                      <div className={"grid grid-cols-2"}>
                        <div className="mb-8">
                          <div className="text-gray-900 font-bold text-xl mb-2">
                            Delivery Information
                          </div>
                          <p className="text-gray-700 text-base">
                            <span className={"font-bold"}>Name : </span>
                            {name}
                          </p>
                          {email ? (
                            <p className="text-gray-700 text-base">
                              <span className={"font-bold"}>Email : </span>
                              {email}
                            </p>
                          ) : null}

                          <p className="text-gray-700 text-base">
                            <span className={"font-bold"}>Phone : </span>
                            {phone}
                          </p>
                          <p className="text-gray-700 text-base">
                            <span className={"font-bold"}>Address : </span>
                            {address}
                          </p>
                          <p className="text-gray-700 text-base">
                            <span className={"font-bold"}>State : </span>
                            {state}
                          </p>
                          <p className="text-gray-700 text-base">
                            <span className={"font-bold"}>City : </span>
                            {city}
                          </p>
                          <p className="text-gray-700 text-base">
                            <span className={"font-bold"}>Pin Code : </span>
                            {pinCode}
                          </p>
                          {landmark ? (
                            <p className="text-gray-700 text-base">
                              <span className={"font-bold"}>Landmark : </span>
                              {landmark}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="mb-8">
                          <div className="text-gray-900 font-bold text-xl mb-2">
                            Payment Information
                          </div>
                          <p className="text-gray-700 text-base flex justify-between">
                            <span className={"font-bold"}>Sub Total : </span>
                            <span className={"flex items-center"}>
                              <span>{(price - tax)?.toFixed(2)}</span>
                            </span>
                          </p>
                          <p className="text-gray-700 text-base flex justify-between">
                            <span className={"font-bold"}>Tax : </span>
                            <span className={"flex items-center"}>
                              + <FaRupeeSign /> {tax}
                            </span>
                          </p>
                          <p className="text-gray-700 text-base flex justify-between border-t my-2">
                            <span className={"font-bold"}>Total : </span>
                            <span className={"flex items-center"}>
                              <FaRupeeSign /> {price?.toFixed(2)}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-1 p-2 bg-white shadow-md">
                <div className={"grid grid-cols-2"}>
                  <img
                    src={require("../assets/images/paymentQr/abhhyam_qr.jpg")}
                    alt="Payment QR"
                    className={"object-contain"}
                  />
                  <div>
                    <h1 className="text-xl font-medium border-b px-2 pb-2">
                      Scan & pay Amount After That Upload Payment Receipt And
                      Submit
                    </h1>

                    <div className={"mt-5"}>
                      <p className={"font-bold"}>Pay Amount</p>
                      <input
                        type="text"
                        readOnly={true}
                        value={price?.toFixed(2)}
                        className={"font-bold w-32 text-center"}
                      />

                      <form onSubmit={paymentDone}>
                        <p className={"font-bold mt-4"}>
                          Upload Payment Receipt
                        </p>
                        <input
                          type="file"
                          name={"payment-receipt"}
                          id={"receipt"}
                          accept={"image/png, image/jpg, image/jpeg"}
                          onChange={onSubmitFile}
                          required
                        />

                        <button
                          disabled={isLoader}
                          type={"submit"}
                          className="bg-blue-500 rounded-sm text-white p-2 w-full mt-5"
                        >
                          {isLoader ? (
                            <i className={"fa fa-spinner fa-spin"}></i>
                          ) : null}
                          &nbsp; Upload
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WebLayout>
  );
}

export default Payment;
