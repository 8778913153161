import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FaCartPlus, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import BtnLoader from "../BtnLoader";
import { useNavigate } from "react-router-dom";
import { useProductDetailsFetch } from "../../hooks/useProductDetailsFetch";
import * as cartActions from "../../store/action/cart";
import { toastMessage } from "../../utils/Message";

const ProductModal = ({
  productModalOpen,
  setProductModalOpen,
  slug,
  queryProductId,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);

  const [previewImage, setPreviewImage] = useState("");
  const [type, setType] = useState("");

  const { productDetails } = useSelector((state) => state.product);

  const [selectedSize, setSelectedSize] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [stockAvailability, setStockAvailability] = useState(false);

  const [productLoader] = useProductDetailsFetch(
    slug,
    queryProductId,
    selectedSize,
    selectedColor,
    type,
  );

  const cartAdd = async () => {
    setIsLoader(true);
    try {
      await dispatch(
        cartActions.cartAdd(
          productDetails.id,
          selectedColor,
          selectedSize,
          productDetails.isVariant,
        ),
      );
      setIsLoader(false);
      toastMessage(
        "Product successfully added to cart",
        "success",
        "top-center",
        2000,
      );
      navigate(`/seller-product-list/${productDetails.seller}`);
    } catch (e) {
      toastMessage(e.message, "danger", "top-center", 5000);
      setIsLoader(false);
    }
  };

  useEffect(() => {
    setSelectedColor("");
    setSelectedSize("");
    if (productDetails.product_image.length > 0) {
      setPreviewImage(productDetails.product_image[0].product_image);
    }
    if (productDetails?.selectedSize) {
      setSelectedSize(productDetails.selectedSize);
    }
    if (productDetails?.selectedColor) {
      setSelectedColor(productDetails.selectedColor);
    }
    if (productDetails.productAvailablility) {
      setStockAvailability(true);
    } else {
      setStockAvailability(false);
    }
  }, [
    productDetails.productAvailablility,
    productDetails.product_image,
    productDetails.selectedColor,
    productDetails.selectedSize,
  ]);

  return (
    <div>
      <Transition.Root show={productModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={setProductModalOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="mt-3">
                      <div className="mt-2">
                        <button
                          className="close_btn"
                          type="button"
                          onClick={() => setProductModalOpen(false)}
                        >
                          <FaTimes />
                        </button>
                        <div className="container mx-auto">
                          <div className="grid grid-cols-1">
                            <div className="lg:py-2 lg:px-4 md:py-2 md:px-4 py-2">
                              <div className="bg-white rounded-md mb-5">
                                {productLoader ? (
                                  <div className={"text-center"}>
                                    <i
                                      className={"fa fa-spinner fa-2x fa-spin"}
                                    ></i>
                                  </div>
                                ) : (
                                  <div className="w-full">
                                    <div className={"items-center"}>
                                      <img
                                        src={previewImage}
                                        className={
                                          "object-contain p-2 w-full h-96"
                                        }
                                      />
                                    </div>

                                    <h2 className="font-semibold lg:text-lg text-xl lg:leading-9 leading-7 text-gray-800">
                                      {productDetails.name}
                                    </h2>

                                    {stockAvailability ? (
                                      <h6 className="font-semibold text-gray-400 text-xs">
                                        Product code :{" "}
                                        {productDetails.productCode}
                                      </h6>
                                    ) : null}

                                    {stockAvailability ? (
                                      <div className={"flex space-x-4 mt-2"}>
                                        <p className="font-semibold text-2xl lg:leading-6 leading-5 mt-4 text-blue-500 italic">
                                          <sup>₹</sup>
                                          {productDetails.selling_price}
                                        </p>

                                        <p className="font-semibold text-xl lg:leading-6 leading-5 mt-4 text-red-500 italic">
                                          <del>MRP : ₹{productDetails.mrp}</del>
                                        </p>

                                        <p className="font-bold text-xs italic w-12 h-12 bg-green-600 text-white p-2 rounded-full flex justify-center items-center">
                                          {productDetails.discount}%
                                          <br />
                                          Off
                                        </p>
                                      </div>
                                    ) : null}

                                    <div className="mt-4">
                                      <div>
                                        {productDetails.color.length > 0 ? (
                                          <div className="mb-4 mt-2">
                                            <div
                                              className={
                                                "flex items-center space-x-2"
                                              }
                                            >
                                              <h2 className="text-sm font-bold text-gray-500">
                                                Color
                                              </h2>

                                              <select
                                                name=""
                                                id=""
                                                onChange={(e) =>
                                                  setSelectedColor(
                                                    e.target.value,
                                                  )
                                                }
                                              >
                                                <option
                                                  value=""
                                                  selected
                                                  disabled
                                                >
                                                  Select Colors
                                                </option>
                                                {productDetails.color.map(
                                                  (color, index) => {
                                                    return (
                                                      <option
                                                        key={index}
                                                        value={color}
                                                        selected={
                                                          color ===
                                                          selectedColor
                                                        }
                                                      >
                                                        {color}
                                                      </option>
                                                    );
                                                  },
                                                )}
                                              </select>
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>

                                      {productDetails.size.length > 0 ? (
                                        <div className="pb-2 border-gray-300 dark:border-gray-700">
                                          <div
                                            className={
                                              "flex items-center space-x-4"
                                            }
                                          >
                                            <h2 className="text-sm font-bold text-gray-500">
                                              Size
                                            </h2>

                                            <ul className="flex -space-x-px h-8 text-sm items-center my-5 gap-3">
                                              {productDetails.size.map(
                                                (size, i) => {
                                                  return (
                                                    <li key={i}>
                                                      <button
                                                        className={`px-3 h-12 leading-tight text-gray-500 border border-gray-300 hover:bg-orange-400 dark:border-gray-700 dark:hover:text-white hover:text-white cursor-pointer ${
                                                          size === selectedSize
                                                            ? "bg-orange-400 text-white"
                                                            : "bg-white"
                                                        }`}
                                                        onClick={() => {
                                                          setSelectedSize(size);
                                                          setType("variants");
                                                        }}
                                                      >
                                                        {size}
                                                      </button>
                                                    </li>
                                                  );
                                                },
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>

                                    {!stockAvailability ? (
                                      <div
                                        className={
                                          "border-2 border-red-400 rounded text-red-500 mt-2"
                                        }
                                      >
                                        This variant is not available yet.
                                      </div>
                                    ) : null}

                                    <p className="font-normal text-base leading-6 text-gray-600 mt-4">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: productDetails.description,
                                        }}
                                      />
                                    </p>
                                    {productDetails.cart ? (
                                      <div className="flex flex-row justify-between border-b py-2">
                                        <button
                                          className="focus:outline-none focus:ring-2 hover:bg-green-500 focus:ring-offset-2 focus:ring-green-500 font-medium text-base leading-4 text-white  bg-green-500 inline-flex items-center py-3 px-4 rounded-sm w-full text-center justify-center"
                                          onClick={() => navigate("/cart")}
                                        >
                                          <FaCartPlus />{" "}
                                          <span className="pl-2">
                                            Go to Cart
                                          </span>
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="border-b py-2">
                                        {stockAvailability ? (
                                          <button
                                            disabled={isLoader}
                                            className={`focus:outline-none focus:ring-2 hover:bg-orange-500 focus:ring-offset-2 focus:ring-orange-500 font-medium text-base leading-4 text-white  bg-orange-500 inline-flex items-center py-3 px-4 rounded-sm w-full text-center justify-center ${
                                              isLoader ? "opacity-70" : ""
                                            }`}
                                            onClick={() => cartAdd()}
                                          >
                                            {isLoader ? <BtnLoader /> : null}
                                            <FaCartPlus />{" "}
                                            <span className="pl-2">
                                              Add to Cart
                                            </span>
                                          </button>
                                        ) : (
                                          <button
                                            disabled={!stockAvailability}
                                            className={`focus:outline-none focus:ring-2 hover:bg-orange-500 focus:ring-offset-2 focus:ring-orange-500 font-medium text-base leading-4 text-white  bg-orange-500 inline-flex items-center py-3 px-4 rounded-sm w-full text-center justify-center opacity-70`}
                                          >
                                            <FaCartPlus />{" "}
                                            <span className="pl-2">
                                              Add to Cart
                                            </span>
                                          </button>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default ProductModal;
