import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import * as cartActions from "../../../store/action/cart";
import { defaultProductImage } from "../../../utils/Constants";
import CityModal from "../../modal/CityModal";
import { toastMessage } from "../../../utils/Message";
import BtnLoader from "../../BtnLoader";
import ProductModal from "../../modal/ProductModal";

function CategoryProducts({
  categorySlug,
  categoryIndex,
  categoryId = 0,
  categoryName,
  categoryImage,
  categoryProducts,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(0);

  const [cityModal, setCityModal] = useState(false);
  const [productModalOpen, setProductModalOpen] = useState(false);
  const [productSlug, setProductSlug] = useState("");
  const [productId, setProductId] = useState("");

  const { storeCity } = useSelector((state) => state.auth);

  const cartAdd = async (product_id, vendor_id, isVariant, slug) => {
    if (!storeCity) {
      setCityModal(true);
      return false;
    }

    if (isVariant === "YES") {
      setProductModalOpen(true);
      setProductId(product_id);
      setProductSlug(slug);
      return false;
    }

    setIsLoader(product_id);
    try {
      await dispatch(cartActions.cartAdd(product_id, "", ""));
      setIsLoader(0);
      toastMessage(
        "Product successfully added to cart",
        "success",
        "top-center",
        2000,
      );
      navigate(`/seller-product-list/${vendor_id}`);
    } catch (e) {
      toastMessage(e.message, "danger", "top-center", 5000);
      setIsLoader(0);
    }
  };

  return (
    <>
      <CityModal cityModal={cityModal} setCityModal={setCityModal} />
      <ProductModal
        productModalOpen={productModalOpen}
        setProductModalOpen={setProductModalOpen}
        slug={productSlug}
        queryProductId={productId}
      />
      <div className="bg-white" key={categoryIndex}>
        <div className="container-fluid mx-auto py-2 px-4">
          <div className="relative bg-red-500 py-1">
            <div className="absolute bottom-0 right-0 h-4 w-4 bg-red-500 transform translate-y-1/2 translate-x-1/2 -rotate-45"></div>
            <div className="container flex items-center justify-between px-6">
              <div className="text-white text-lg">{categoryName}</div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 justify-center items-center lg:grid-cols-7">
            {categoryProducts.map((proData, index) => (
              <div className="w-full" key={index}>
                <Link
                  to={`/product-details/${proData.product_slug}?product_id=${proData.id}`}
                  title={proData.product_name}
                >
                  <div className="border">
                    <div className="relative bg-white p-2">
                      <p className="font-bold text-xs mb-2 italic w-12 h-12 bg-green-600 text-white p-2 rounded-full absolute right-1 flex justify-center items-center">
                        {proData.discount}% <br /> Off
                      </p>

                      <img
                        src={proData.product_image}
                        alt={proData.product_name}
                        onError={(event) => {
                          event.target.src = defaultProductImage;
                          event.onerror = null;
                        }}
                        className="object-fit w-full h-32 mx-auto object-contain"
                      />
                    </div>
                    <div className="p-3 ">
                      <Link to="#" className="text-sm text-center">
                        <span className={"flex justify-around"}>
                          <p className="text-blue-500 font-bold text-sm mb-2">
                            Rs. {proData.selling_price}
                          </p>
                          <p className="text-red-500 font-bold text-sm mb-2 italic">
                            <del>MRP : Rs. {proData.mrp}</del>
                          </p>
                        </span>

                        <p className={"font-bold text-field"}>
                          {proData.product_name}
                        </p>

                        <button
                          onClick={() =>
                            cartAdd(
                              proData.id,
                              proData.vendor_id,
                              proData.isVariant,
                              proData.slug_url,
                            )
                          }
                          className={`mt-2 w-full focus:outline-none focus:ring-2 hover:bg-blue-500 hover:text-white focus:ring-offset-2 focus:ring-blue-500 font-medium text-base leading-4 inline-flex items-center text-blue-600 border border-blue-600 text-blue mb-3 p-2 rounded-sm  text-center justify-center ${
                            isLoader === proData.id ? "cursor-not-allowed" : ""
                          }`}
                          disabled={isLoader === proData.id}
                        >
                          {isLoader === proData.id ? <BtnLoader /> : null}
                          <span className="pl-2">Add to Cart</span>
                        </button>
                      </Link>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default CategoryProducts;
