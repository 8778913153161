import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  LOGO,
  MSME_CITY,
  MSME_SEARCH,
  SELLER_LOGIN_URL,
} from "../utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as cartActions from "../store/action/cart";
import CategoryNav from "./CategoryNav";
import { useCities } from "../hooks/useCities";
import * as authActions from "../store/action/auth";
import { toastMessage } from "../utils/Message";
import Select from "react-select";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const place = useLocation();

  const { city } = useSelector((state) => state.menu);
  const { accessToken, storeCity, storeSearch } = useSelector(
    (state) => state.auth,
  );
  const { cartCount } = useSelector((state) => state.cart);

  const [location, setLocation] = useState("");
  const [searchText, setSearchText] = useState("");

  useCities();

  const cartItemCount = useCallback(() => {
    (async () => {
      try {
        await dispatch(cartActions.cartCount());
      } catch (error) {}
    })();
  }, [dispatch]);

  useEffect(() => {
    cartItemCount();
  }, [cartItemCount]);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (location?.value?.length === undefined && searchText?.length === 0) {
      toastMessage(
        "Please select either a city or\n a search before proceeding.!",
        "danger",
        "top-center",
        2000,
      );
      return false;
    }

    if (storeCity !== location?.value) {
      await dispatch(cartActions.cartClean());
    }

    if (location?.value) {
      localStorage.setItem(MSME_CITY, location?.value);
      dispatch(authActions.storeCity(location?.value));
    }

    if (searchText) {
      localStorage.setItem(MSME_SEARCH, searchText);
      dispatch(authActions.storeSearch(searchText));
    }

    navigate(
      `/search-product/${location?.value ? location?.value : "city"}/${
        searchText ? window.btoa(searchText) : window.btoa("search")
      }`,
    );
  };

  const handleSearchingText = (text) => {
    setSearchText(text);
  };

  const closeText = () => {
    setSearchText("");
    dispatch(authActions.storeSearch(""));
    localStorage.setItem(MSME_SEARCH, "");
  };

  const data = city.map((c) => {
    return {
      label: c.name,
      value: c.name,
    };
  });

  useEffect(() => {
    if (storeCity) {
      setLocation({
        label: storeCity,
        value: storeCity,
      });
    }

    if (place.pathname === "/") {
      setSearchText("");
    } else if (storeSearch) {
      setSearchText(storeSearch);
    } else {
      setSearchText("");
    }
  }, [navigate, place.pathname, storeCity, storeSearch]);

  return (
    <>
      <div className="w-full mx-auto px-2 sm:px-4 lg:px-4">
        <div className="relative flex items-center justify-between">
          <div className="flex flex-1 items-center justify-between ">
            <Link to={"/"}>
              <div className="flex flex-shrink-0 items-center">
                <img src={LOGO} alt="" className={"w-auto h-24"} />
              </div>
            </Link>

            <div className="hidden sm:ml-6 sm:block" style={{ width: "50%" }}>
              <form onSubmit={handleSearch}>
                <div className={"flex justify-between border-2"}>
                  <Select
                    value={location}
                    onChange={setLocation}
                    options={data}
                    placeholder={"City"}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        borderWidth: 0,
                        width: 150,
                        padding: 5,
                      }),
                      indicatorSeparator: (provided, state) => ({
                        ...provided,
                        width: 0,
                      }),
                    }}
                  />

                  <div className="input-container w-full">
                    <input
                      className="bg-white  border-0 py-1 sm:text-sm focus:border-o w-full border-r-0 border-l-2 border-gray-200 input-field"
                      style={{
                        height: 50,
                        borderRadius: 5,
                      }}
                      type="text"
                      autoComplete={"off"}
                      value={`${searchText}`}
                      onChange={(e) => handleSearchingText(e.target.value)}
                      placeholder={"Search..."}
                    />
                    {searchText.length > 0 ? (
                      <i
                        className="fas fa-close input-icon"
                        style={{ color: "black" }}
                        onClick={() => closeText()}
                      ></i>
                    ) : null}
                  </div>

                  <button
                    type={"submit"}
                    className={"bg-green-500 text-white w-48"}
                  >
                    Search
                  </button>
                </div>
              </form>
            </div>
            <div className="">
              <div className="flex space-x-4">
                {accessToken ? (
                  <a href={SELLER_LOGIN_URL} className="flex items-center pr-2">
                    <img
                      src={require("../assets/images/icon/2.png")}
                      width={30}
                      height={30}
                      className="pr-2"
                      alt="img"
                    />
                    USER
                  </a>
                ) : (
                  <div className="flex items-center pr-2 cursor-pointer">
                    <img
                      src={require("../assets/images/icon/2.png")}
                      width={30}
                      height={30}
                      className="pr-2"
                      alt="img"
                    />
                    <Link to={SELLER_LOGIN_URL} target="_blank">
                      Login / Sign Up
                    </Link>
                  </div>
                )}

                <Link to={"/cart"} className="relative inline-flex">
                  <img
                    src={require("../assets/images/icon/cart.png")}
                    alt="cart"
                    width={30}
                    height={30}
                  />
                  {cartCount > 0 && (
                    <div
                      className="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-1 -right-1 dark:border-gray-900"
                      style={{ fontSize: 8 }}
                    >
                      {cartCount}
                    </div>
                  )}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CategoryNav />
    </>
  );
};

export default Header;
