import Carousel from "react-multi-carousel";
import { colThree } from "../../data/dummyData";

const PremiumBrands = ({ brandList }) => {
  return (
    <div className="bg-white">
      <div className="mx-auto p-4">
        <div className="grid grid-cols-1 gap-2 rounded-lg px-5 mb-4">
          <div className="col-span-3">
            <div className="grid grid-cols-1 gap-2">
              <div className={"flex justify-between items-center"}>
                <h3 className="text-lg font-bold uppercase">PREMIUM BRANDS</h3>
              </div>
              <Carousel
                swipeable={false}
                draggable={false}
                showDots={false}
                responsive={colThree}
                ssr={true}
                autoPlay={false}
                autoPlaySpeed={5000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerclassName="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                deviceType={"desktop"}
                dotListclassName="custom-dot-list-style"
                itemclassName="carousel-item-padding-40-px">
                {brandList.map((item) => (
                  <div className="col-span-12 sm:col-span-6 md:col-span-3 m-1">
                    <card className="w-full flex flex-col">
                      <div className="relative">
                        <a href="#">
                          <img
                            src={item.image}
                            className="w-96 h-60 min-h-fit  border border-zinc-300 rounded-lg"
                            alt="img"
                          />
                        </a>
                      </div>
                    </card>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PremiumBrands;
