import { BASE_URL } from "../../utils/Constants";

export const GET_MENU_SUBMENU = "GET_MENU_SUBMENU";

export const GET_CITY = "GET_CITY";

export const getMenuSubMenu = () => {
  return async (dispatch) => {
    const response = await fetch(`${BASE_URL}menu-submenu`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const result = await response.json();
      throw new Error(result.message);
    }

    const result = await response.json();

    if (result.status) {
      dispatch({
        type: GET_MENU_SUBMENU,
        data: result.data,
      });
    } else {
      throw new Error(result.message);
    }
  };
};

export const getCities = () => {
  return async (dispatch) => {
    const response = await fetch(`${BASE_URL}get-cities`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const result = await response.json();
      throw new Error(result.message);
    }

    const result = await response.json();

    if (result.status) {
      dispatch({
        type: GET_CITY,
        data: result.data,
      });
    } else {
      throw new Error(result.message);
    }
  };
};
