import {
  BASE_URL,
  MSME_USER_ID,
  MSME_USER_TOKEN,
  MSME_USER_TYPE,
} from "../../utils/Constants";
import { storeCookie } from "../../utils/CookieAction";

export const CLEAN_ACCOUNT_EXISTANCE = "CLEAN_ACCOUNT_EXISTANCE";

export const ACCOUNT_EXISTANCE = "ACCOUNT_EXISTANCE";

export const AUTHENTICATION = "AUTHENTICATION";

export const AUTH = "AUTH";

export const NETWORK_REGISTRATION = "NETWORK_REGISTRATION";

export const REGISTRATION = "REGISTRATION";

export const STORE_CITY = "STORE_CITY";

export const STORE_SEARCH = "STORE_SEARCH";

export const accountExistAnce = (phoneNumber) => {
  return async (dispatch) => {
    const response = await fetch(`${BASE_URL}account/existAnce`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ phoneNumber }),
    });

    if (!response.ok) {
      const result = await response.json();
      throw new Error(result.msg);
    }

    const result = await response.json();

    if (result.status) {
      dispatch({
        type: ACCOUNT_EXISTANCE,
        data: result.msg,
      });
    } else {
      throw new Error(result.msg);
    }
  };
};

export const register = (
  phoneNumber,
  name,
  referral_by,
  password,
  email,
  state,
  address,
) => {
  return async (dispatch) => {
    const response = await fetch(`${BASE_URL}account/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phoneNumber,
        name,
        referral_by,
        password,
        email,
        state,
        address,
      }),
    });

    if (!response.ok) {
      const result = await response.json();
      throw new Error(result.msg);
    }

    const result = await response.json();

    if (result.status) {
      dispatch({
        type: AUTHENTICATION,
      });
      dispatch(auth(result.data.token));
      await saveToken(result.data.token, result.data.user_type, result.data.id);
    } else {
      throw new Error(result.msg);
    }
  };
};

export const login = (phone, password) => {
  return async (dispatch) => {
    const response = await fetch(`${BASE_URL}account/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ mobile: phone, password }),
    });

    if (!response.ok) {
      const result = await response.json();
      throw new Error(result.msg);
    }

    const result = await response.json();

    if (result.status) {
      dispatch({
        type: AUTHENTICATION,
      });
      dispatch(auth(result.data.token));
      await saveToken(result.data.token, result.data.user_type, result.data.id);
    } else {
      throw new Error(result.msg);
    }
  };
};

export const auth = (accessToken) => {
  return {
    type: AUTH,
    accessToken: accessToken,
  };
};

export const saveToken = async (accessToken, userType, userId) => {
  await storeCookie(MSME_USER_TOKEN, JSON.stringify(accessToken));
  await storeCookie(MSME_USER_TYPE, JSON.stringify(userType));
  await storeCookie(MSME_USER_ID, userId);
};

export const cleanExistAnce = () => {
  return {
    type: CLEAN_ACCOUNT_EXISTANCE,
  };
};

export const networkRegistration = (
  name,
  mobile,
  email,
  password,
  address,
  referral_by,
) => {
  return async (dispatch) => {
    const response = await fetch(`${BASE_URL}network/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        mobile,
        email,
        password,
        address,
        referral_by,
      }),
    });

    if (!response.ok) {
      const result = await response.json();
      throw new Error(result.msg);
    }

    const result = await response.json();

    if (result.status) {
      dispatch({
        type: NETWORK_REGISTRATION,
        data: result.msg,
      });
    } else {
      throw new Error(result.msg);
    }
  };
};

export const registration = (
  name_of_entity,
  mobile,
  email,
  password,
  referral_by,
) => {
  return async (dispatch) => {
    const response = await fetch(`${BASE_URL}registration`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name_of_entity,
        mobile,
        email,
        password,
        referral_by,
      }),
    });

    if (!response.ok) {
      const result = await response.json();
      throw new Error(result.msg);
    }

    const result = await response.json();

    if (result.status) {
      dispatch({
        type: REGISTRATION,
      });
      return result;
    } else {
      throw new Error(result.msg);
    }
  };
};

export const storeCity = (city) => {
  return {
    type: STORE_CITY,
    storeCity: city,
  };
};
export const storeSearch = (search) => {
  return {
    type: STORE_SEARCH,
    storeSearch: search,
  };
};
