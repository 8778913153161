import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-multi-carousel/lib/styles.css";
import "../App.css";
import Reviews from "../components/reviews/Reviews";
import PremiumBrands from "../components/brands/PremiumBrands";
import CategoryProducts from "../components/products/category/products";
import WebLayout from "../layout/WebLayout";
import { useCategoryProductsFetch } from "../hooks/useCategoryProductsFetch";
import { useSelector } from "react-redux";
import MainLoader from "../components/MainLoader";
import { useHomeBrandFetch } from "../hooks/useHomeBrandFetch";

function Home() {
  const { randomCategoryProducts } = useSelector((state) => state.product);
  const { storeCity } = useSelector((state) => state.auth);
  const [categoryLoader] = useCategoryProductsFetch("random", storeCity);
  const { brand } = useSelector((state) => state.brand);
  useHomeBrandFetch();

  if (categoryLoader) {
    return <MainLoader />;
  }

  return (
    <WebLayout pagname={Home}>
      {randomCategoryProducts.length > 0 &&
        randomCategoryProducts.map((data, index) => {
          if (data.products.length > 0) {
            return (
              <CategoryProducts
                categorySlug={data.slug_url}
                categoryIndex={index}
                categoryId={data.id}
                categoryName={data.name}
                categoryImage={data.image}
                categoryProducts={data.products}
              />
            );
          }
        })}

      <Reviews />
      <PremiumBrands brandList={brand} />
    </WebLayout>
  );
}

export default Home;
