import { defaultProductImage } from "../utils/Constants";
import { FaCartPlus } from "react-icons/fa";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as cartActions from "../store/action/cart";
import { toastMessage } from "../utils/Message";
import CityModal from "./modal/CityModal";
import BtnLoader from "./BtnLoader";
import ProductModal from "./modal/ProductModal";

function SearchProductCard({ searchProducts }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(0);

  const [cityModal, setCityModal] = useState(false);
  const [productModalOpen, setProductModalOpen] = useState(false);
  const [productSlug, setProductSlug] = useState("");
  const [productId, setProductId] = useState("");
  const { storeCity } = useSelector((state) => state.auth);

  const cartAdd = async (product_id, vendor_id, isVariant, slug) => {
    if (!storeCity) {
      setCityModal(true);
      return false;
    }

    if (isVariant === "YES") {
      setProductModalOpen(true);
      setProductId(product_id);
      setProductSlug(slug);
      return false;
    }

    setIsLoader(product_id);
    try {
      await dispatch(cartActions.cartAdd(product_id, "", ""));
      await dispatch(cartActions.cartCount());
      await dispatch(cartActions.cartList(storeCity));
      setIsLoader(0);
      toastMessage(
        "Product successfully added to cart",
        "success",
        "top-center",
        2000,
      );
      navigate(`/seller-product-list/${vendor_id}`);
    } catch (e) {
      toastMessage(e.message, "danger", "top-center", 5000);
      setIsLoader(0);
    }
  };

  return (
    <>
      <CityModal cityModal={cityModal} setCityModal={setCityModal} />
      <ProductModal
        productModalOpen={productModalOpen}
        setProductModalOpen={setProductModalOpen}
        slug={productSlug}
        queryProductId={productId}
      />
      <div className="min-h-screen mx-auto">
        <div className="grid grid-cols-7 gap-2 mb-10">
          {searchProducts &&
            searchProducts?.map((proData, index) => {
              return (
                <div className="w-full" key={proData.id}>
                  <div className="border">
                    <div className="relative bg-white p-2">
                      <p className="font-bold text-xs mb-2 italic w-12 h-12 bg-green-600 text-white p-2 rounded-full absolute right-1 flex justify-center items-center">
                        {proData.discount}% <br /> Off
                      </p>

                      <img
                        src={proData.image}
                        alt={proData.name}
                        onError={(event) => {
                          event.target.src = defaultProductImage;
                          event.onerror = null;
                        }}
                        className="object-fit w-full h-48 mx-auto object-contain"
                      />
                    </div>
                    <div className="p-3 text-center">
                      <span className={"flex justify-around"}>
                        <p className="text-blue-500 font-bold text-sm mb-2 italic">
                          Rs. {proData.sellingPrice}
                        </p>
                        <p className="text-red-500 font-bold text-sm mb-2 italic">
                          <del>MRP : {proData.mrp}</del>
                        </p>
                      </span>
                      <p className={"font-bold text-field"}>{proData.name}</p>
                      <button
                        onClick={() =>
                          cartAdd(
                            proData.id,
                            proData.seller,
                            proData.isVariant,
                            proData.product_slug,
                          )
                        }
                        className={`mt-2 w-full focus:outline-none focus:ring-2 hover:bg-blue-500 hover:text-white focus:ring-offset-2 focus:ring-blue-500 font-medium text-base leading-4 inline-flex items-center text-blue-600 border border-blue-600 text-blue mb-3 p-2 rounded-sm  text-center justify-center ${
                          isLoader === proData.id ? "cursor-not-allowed" : ""
                        }`}
                        disabled={isLoader === proData.id}
                      >
                        {isLoader === proData.id ? <BtnLoader /> : null}
                        <FaCartPlus /> <span className="pl-2">Add to Cart</span>
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}

export default SearchProductCard;
