import {
  GET_CATEGORY,
  GET_SUB_AND_CHILD_CATEGORY,
  GET_SUB_CATEGORY,
} from "../action/category";
import { Category } from "../../datamodels/Category";
import { SubCategory } from "../../datamodels/SubCategory";
import { SubAndChildCategory } from "../../datamodels/SubAndChildCategory";
const initialState = {
  categories: [],
  subCategories: [],
  subAndChildCategories: [],
};

const CategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORY: {
      const { data } = action;
      return {
        ...state,
        categories: data.map(
          (c) => new Category(c.id, c.name, c.image, c.icon, c.slug_url)
        ),
      };
    }

    case GET_SUB_CATEGORY: {
      const { data } = action;
      return {
        ...state,
        subCategories: data.map(
          (c) => new SubCategory(c.id, c.name, c.image, c.icon, c.slug_url)
        ),
      };
    }

    case GET_SUB_AND_CHILD_CATEGORY: {
      const { data } = action;
      return {
        ...state,
        subAndChildCategories: data.map(
          (c) =>
            new SubAndChildCategory(c.id, c.name, c.slug_url, c.childCategory)
        ),
      };
    }

    default:
      return state;
  }
};

export default CategoryReducer;
