export class Products {
  constructor(
    id = "",
    name = "",
    image = "",
    mrp = "",
    selling_price = "",
    slug = "",
    size = "",
    color = "",
    isVariant = "",
    seller = "",
    discount = "",
    pv = "",
  ) {
    this.id = id;
    this.name = name;
    this.image = image;
    this.mrp = mrp;
    this.sellingPrice = selling_price;
    this.product_slug = slug;
    this.size = size;
    this.color = color;
    this.isVariant = isVariant;
    this.seller = seller;
    this.discount = discount;
    this.pv = pv;
  }
}
