export const BASE_URL = "https://msmestores.com/api/v1/website/";
//export const BASE_URL = "http://localhost/msme_backend_api/api/v1/website/";
export const LOGO = require("../assets/logo.png");
export const defaultCategoryImage = require("../assets/images/product/colthes/1.jpg");
export const defaultProductImage = require("../assets/images/no_image.png");
export const MSME_USER_TOKEN = "@ABHHYAM_TRADING:USER_TOKEN";
export const MSME_USER_ORDER_DETAILS = "@ABHHYAM_TRADING:USER_ORDER_DETAILS";
export const MSME_USER_TYPE = "@ABHHYAM_TRADING:USER_TYPE";
export const MSME_USER_ID = "@ABHHYAM_TRADING:USER_ID";
export const MSME_USER_ADDRESS = "@ABHHYAM_TRADING:USER_ADDRESS";
export const AOA = require("../assets/certification/aoa.pdf");
export const MOA = require("../assets/certification/moa.pdf");
export const PAN = require("../assets/certification/pan.pdf");
export const COI = require("../assets/certification/coi.pdf");
export const SELLER_LOGIN_URL = "https://app.amsmetrading.com";
export const MSME_CITY = "@ABHHYAM_TRADING:MSME_CITY";
export const MSME_SEARCH = "@ABHHYAM_TRADING:MSME_SEARCH";
