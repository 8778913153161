import {
  ACCOUNT_EXISTANCE,
  AUTH,
  CLEAN_ACCOUNT_EXISTANCE,
  NETWORK_REGISTRATION,
  REGISTRATION,
  STORE_CITY,
  STORE_SEARCH,
} from "../action/auth";

const initialState = {
  accountExistAnce: "",
  accessToken: "",
  storeCity: "",
  storeSearch: "",
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_EXISTANCE: {
      return {
        ...state,
        accountExistAnce: action.data,
      };
    }

    case CLEAN_ACCOUNT_EXISTANCE: {
      return {
        ...state,
        accountExistAnce: "",
      };
    }

    case AUTH: {
      return {
        ...state,
        accessToken: action.accessToken,
      };
    }

    case NETWORK_REGISTRATION: {
      return {
        ...state,
      };
    }

    case REGISTRATION: {
      return {
        ...state,
      };
    }

    case STORE_CITY: {
      return {
        ...state,
        storeCity: action.storeCity,
      };
    }

    case STORE_SEARCH: {
      return {
        ...state,
        storeSearch: action.storeSearch,
      };
    }

    default:
      return state;
  }
};

export default AuthReducer;
