import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as productActions from "../store/action/product";

export const useProductFetch = (
  page = 1,
  categoryId = "",
  subCategoryId = "",
  childCategoryId = "",
  city = "",
) => {
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);

  const productFetch = useCallback(() => {
    (async () => {
      setIsLoader(true);
      try {
        await dispatch(
          productActions.getProducts(
            page,
            categoryId,
            subCategoryId,
            childCategoryId,
            city,
          ),
        );
        setIsLoader(false);
      } catch (error) {
        setIsLoader(false);
      }
    })();
  }, [categoryId, childCategoryId, city, dispatch, page, subCategoryId]);

  useEffect(() => {
    productFetch();
  }, [productFetch]);

  return [isLoader];
};
