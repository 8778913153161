import React from "react";
import { useSelector } from "react-redux";
import { useMenuSubMenuFetch } from "../hooks/useMenuSubMenuFetch";
import { Link } from "react-router-dom";

const CategoryNav = () => {
  const { menuSubMenus } = useSelector((state) => state.menu);

  useMenuSubMenuFetch();

  return (
    <nav className="bg-white text-black border-b">
      <ul className="category-list bg-blue-500">
        {menuSubMenus.length > 0 &&
          menuSubMenus.map((val, index) => (
            <li key={index} className="block border-r-4">
              <Link to={`/category/${val.slug_url}`}>
                <span
                  className={"flex items-center text-white"}
                  style={{ fontSize: 16 }}
                >
                  {val.name}
                </span>
              </Link>
            </li>
          ))}
      </ul>
    </nav>
  );
};

export default CategoryNav;
