import { BASE_URL } from "../../utils/Constants";

export const GET_HOME_BRAND = "GET_HOME_BRAND";

export const getHomeBrand = () => {
  return async (dispatch) => {
    const response = await fetch(`${BASE_URL}brand-list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const result = await response.json();
      throw new Error(result.message);
    }
    const result = await response.json();

    if (result.status) {
      dispatch({
        type: GET_HOME_BRAND,
        data: result.data,
      });
    } else {
      throw new Error(result.message);
    }
  };
};
