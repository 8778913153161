export const popular = [
  "AAC Block",
  "Acrylic Sheet",
  "Agarbatti",
  "Agricultural Equipment",
  "Air Compressor",
  "Aluminium Sheet",
  "Ball Bearing",
  "Barcode Scanner",
  "Billing Machine",
  "Canopies",
  "Chemicals",
  "Control Panel",
  "CPAP Machine",
  "Cranes",
  "Curtain",
  "Deep Freezer",
  "Earthmover",
  "Electric Motor",
  "Elevator",
  "Fabric",
  "Gi Pipe",
  "Glow Sign Board",
  "HDPE Pipe",
  "Identity Card",
  "Jaquar Bathroom Fitting",
  "Kirloskar Generators",
  "Lathe Machine",
  "LED Light",
  "Mattress",
  "Microtek Inverters",
  "Modular Kitchens",
  "Name Plate",
  "Packaging Plant",
  "Pamphlet",
  "paper Bag",
  "Paver Block",
  "Pull Down",
  "Pumps",
  "Pvc Panel",
  "Rolling Shutter",
  "Scrap",
  "Sewage Treatment Plants",
  "Sofas",
  "Solar Power Plants",
  "Steel Rack",
  "Tarpaulins",
  "UPVC White Door",
  "Water Cooler",
  "Water Pumps",
  "Water Treatment Plant",
];
