import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as productActions from "../store/action/product";
import { useParams } from "react-router-dom";

export const useSellerProductFetch = () => {
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);
  const { vendor_id } = useParams();

  const sellerProductFetch = useCallback(() => {
    (async () => {
      setIsLoader(true);
      try {
        await dispatch(productActions.getSellerWiseProducts(vendor_id));
        setIsLoader(false);
      } catch (error) {
        setIsLoader(false);
      }
    })();
  }, [dispatch, vendor_id]);

  useEffect(() => {
    sellerProductFetch();
  }, [sellerProductFetch]);

  return [isLoader];
};
