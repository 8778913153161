import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as menuActions from "../store/action/menu";

export const useCities = () => {
  const dispatch = useDispatch();

  const getCity = useCallback(() => {
    (async () => {
      try {
        await dispatch(menuActions.getCities());
      } catch (error) {}
    })();
  }, [dispatch]);

  useEffect(() => {
    getCity();
  }, [getCity]);

  return [];
};
