import {
  ADD_CART,
  CART_CLEAN,
  CART_COUNT,
  LIST_CART,
  REMOVE_CART,
  UPDATE_CART,
} from "../action/cart";
import { CartDataModel } from "../../datamodels/CartDataModel";
import { CLEAR_CART } from "../action/checkout";

const initialState = {
  cartList: new CartDataModel(),
  cartCount: 0,
  deliveryAmount: 0,
  minimumOrderAmount: 0,
  sellerId: 0,
};

const CartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CART: {
      return {
        ...state,
      };
    }

    case LIST_CART: {
      const { cart, deliveryAmount, minimumOrderAmount, sellerId } =
        action.data;

      const listing = cart.map(
        (cart) =>
          new CartDataModel(
            cart.id,
            cart.product_id,
            cart.qty,
            cart.color,
            cart.size,
            cart.mrp,
            cart.sell_price,
            cart.product_name,
            cart.product_slug,
            cart.max_sale_qty,
            cart.min_sale_qty,
            cart.product_image,
            cart.qty * cart.sell_price,
            cart.tax,
            cart.pv,
          ),
      );

      return {
        ...state,
        cartList: listing,
        deliveryAmount,
        minimumOrderAmount,
        sellerId,
      };
    }

    case REMOVE_CART: {
      const list = [...state.cartList];
      let counting = 0;
      const stateCount = state.cartCount;
      const findIndex = list.findIndex((c) => c.id === action.cartId);
      if (findIndex > -1) {
        list.splice(findIndex, 1);
        counting = parseInt(stateCount) - parseInt(action.min_sale_qty);
      }
      return {
        ...state,
        cartList: list,
        cartCount: counting,
      };
    }

    // case UPDATE_CART: {
    //   const { data } = action;
    //   let count = 0;
    //   const list = [...state.cartList];
    //   const stateCount = state.cartCount;
    //   const findIndex = list.findIndex(
    //     (c) => c.id === data.cart_id && c.product_id === data.product_id,
    //   );
    //   if (findIndex > -1) {
    //     if (data.type === "increase") {
    //       list[findIndex].qty =
    //         Number(list[findIndex].qty) + Number(list[findIndex].min_sale_qty);
    //       list[findIndex].totalPrice =
    //         Number(list[findIndex].sell_price) * Number(list[findIndex].qty);
    //       count = parseInt(stateCount) + Number(list[findIndex].min_sale_qty);
    //     } else {
    //       list[findIndex].qty =
    //         Number(list[findIndex].qty) - Number(list[findIndex].min_sale_qty);
    //       list[findIndex].totalPrice =
    //         Number(list[findIndex].sell_price) * Number(list[findIndex].qty);
    //       count = parseInt(stateCount) - Number(list[findIndex].min_sale_qty);
    //       if (list[findIndex].qty === 0) {
    //         list.splice(findIndex, 1);
    //       }
    //     }
    //   }
    //   return {
    //     ...state,
    //     cartList: list,
    //     cartCount: count,
    //   };
    // }

    case UPDATE_CART: {
      const { data } = action;
      let count = 0;
      const list = [...state.cartList];
      const stateCount = state.cartCount;
      const findIndex = list.findIndex(
        (c) => c.id === data.cart_id && c.product_id === data.product_id,
      );
      if (findIndex > -1) {
        list[findIndex].qty = Number(data.qty);
        list[findIndex].totalPrice =
          Number(list[findIndex].sell_price) * Number(data.qty);

        if (list[findIndex].qty === 0) {
          list.splice(findIndex, 1);
        }
      }
      return {
        ...state,
        cartList: list,
        cartCount: count,
      };
    }

    case CART_COUNT: {
      return {
        ...state,
        cartCount: action.data,
      };
    }

    case CLEAR_CART: {
      return {
        ...state,
        cartCount: 0,
      };
    }

    case CART_CLEAN: {
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

export default CartReducer;
