import {
  GET_CATEGORY_STATIC_PRODUCTS,
  GET_CATEGORY_RANDOM_PRODUCTS,
  GET_PLACES_PRODUCTS,
  GET_PRODUCTS,
  GET_PRODUCT_DETAILS,
  GET_SELLER_WISE_PRODUCTS,
  GET_SEARCH_PRODUCTS_NAME,
  GET_SEARCH_PRODUCTS,
} from "../action/product";
import { CategoryProducts } from "../../datamodels/CategoryProducts";
import { Products } from "../../datamodels/Products";
import { ProductDetails } from "../../datamodels/ProductDetails";

const initialState = {
  staticCategoryProducts: new CategoryProducts(),
  randomCategoryProducts: new CategoryProducts(),
  placesProducts: new Products(),
  products: new Products(),
  currentPage: 0,
  lastPage: 0,
  from: 0,
  to: 0,
  totalData: 0,
  productDetails: new ProductDetails(),
  sellerProductsList: new Products(),
  productNames: [],
  searchProducts: new Products(),
  cartSeller: {
    name: "",
    accountId: "",
  },
};

const ProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORY_STATIC_PRODUCTS: {
      const { data } = action;
      return {
        ...state,
        staticCategoryProducts: data.map(
          (c) =>
            new CategoryProducts(c.id, c.name, c.slug_url, c.image, c.products),
        ),
      };
    }

    case GET_CATEGORY_RANDOM_PRODUCTS: {
      const { data } = action;
      return {
        ...state,
        randomCategoryProducts: data.map(
          (c) =>
            new CategoryProducts(c.id, c.name, c.slug_url, c.image, c.products),
        ),
      };
    }

    case GET_PLACES_PRODUCTS: {
      const { data } = action;
      return {
        ...state,
        placesProducts: data.map(
          (c) =>
            new Products(
              c.id,
              c.product_name,
              c.product_image,
              c.mrp,
              c.selling_price,
              c.product_slug,
              "",
              "",
              c.is_variant,
              c.vendor_id,
              c.discount,
              c.buyer_pv,
            ),
        ),
      };
    }

    case GET_PRODUCTS: {
      const { payload } = action;

      return {
        ...state,
        products: payload.data.map(
          (c) =>
            new Products(
              c.id,
              c.product_name,
              c.product_image,
              c.mrp,
              c.selling_price,
              c.product_slug,
              "",
              "",
              c.is_variant,
              c.vendor_id,
              c.discount,
              c.buyer_pv,
            ),
        ),
        currentPage: payload.currentPage,
        lastPage: payload.lastPage,
        from: payload.from,
        to: payload.to,
        totalData: payload.totalData,
      };
    }

    case GET_PRODUCT_DETAILS: {
      const { payload } = action;

      return {
        ...state,
        productDetails: new ProductDetails(
          payload.productId,
          payload.product_name,
          payload.product_code,
          payload.barcode,
          payload.mrp,
          payload.selling_price,
          payload.discount,
          payload.pv,
          payload.min_sale_qty,
          payload.description,
          payload.size,
          payload.color,
          payload.is_variant,
          payload.product_available,
          payload.cart,
          payload.product_image,
          payload.seller,
          payload.selectedColor,
          payload.selectedSize,
        ),
      };
    }

    case GET_SELLER_WISE_PRODUCTS: {
      const { data, user } = action.payload;
      return {
        ...state,
        sellerProductsList: data.map(
          (c) =>
            new Products(
              c.id,
              c.product_name,
              c.product_image,
              c.mrp,
              c.selling_price,
              c.product_slug,
              "",
              "",
              c.is_variant,
              c.vendor_id,
              c.discount,
              c.buyer_pv,
            ),
        ),
        cartSeller: {
          name: user.name_of_entity,
          accountId: user.account_id,
        },
      };
    }

    case GET_SEARCH_PRODUCTS_NAME: {
      const { data } = action;

      return {
        ...state,
        productNames: data,
      };
    }

    case GET_SEARCH_PRODUCTS: {
      const { data } = action;
      return {
        ...state,
        searchProducts: data.map(
          (c) =>
            new Products(
              c.id,
              c.product_name,
              c.product_image,
              c.mrp,
              c.selling_price,
              c.product_slug,
              "",
              "",
              c.is_variant,
              c.vendor_id,
              c.discount,
              c.buyer_pv,
            ),
        ),
      };
    }

    default:
      return state;
  }
};

export default ProductReducer;
