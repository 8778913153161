import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as productActions from "../store/action/product";
import { useParams } from "react-router-dom";

export const useSearchProductFetch = () => {
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);

  const { city, name } = useParams();

  const searchProductFetch = useCallback(() => {
    (async () => {
      setIsLoader(true);
      try {
        await dispatch(productActions.getSearchProducts(city, name));
        setIsLoader(false);
      } catch (error) {
        setIsLoader(false);
      }
    })();
  }, [dispatch, name, city]);

  useEffect(() => {
    searchProductFetch();
  }, [searchProductFetch]);

  return [isLoader];
};
