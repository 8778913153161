import { useSelector } from "react-redux";
import WebLayout from "../layout/WebLayout";
import { useHomeFaqFetch } from "../hooks/useHomeFaqFetch";
import FaqList from "../components/Faq/FaqList";

function Faq() {
  const { faq } = useSelector((state) => state.faq);
  useHomeFaqFetch();

  return (
    <WebLayout pageName={"Certification"}>
      <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">
        <h3 className="text-center text-2xl mb-5">
          Frequently Asked Questions
        </h3>
        <div className="grid sm:grid-cols-2 grid-cols-2 sm:gap-8 gap-4">
          {faq?.map((faqDta, index) => {
            return (
              <FaqList
                index={index}
                question={faqDta.question}
                answer={faqDta.answer}
              />
            );
          })}
        </div>
      </div>
    </WebLayout>
  );
}

export default Faq;
