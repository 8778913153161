import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as cartActions from "../store/action/cart";
import { storeCity } from "../store/action/auth";

export const useCartFetch = (storeCity) => {
  const dispatch = useDispatch();

  const cartFetch = useCallback(() => {
    (async () => {
      try {
        await dispatch(cartActions.cartList(storeCity));
      } catch (error) {}
    })();
  }, [dispatch, storeCity]);

  useEffect(() => {
    cartFetch();
  }, [cartFetch]);

  return [];
};
