import { GET_HOME_BRAND } from "../action/brand";
import { Brand } from "../../datamodels/Brand";
const initialState = {
  brand: [],
};

const BrandReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOME_BRAND: {
      const { data } = action;
      return {
        ...state,
        brand: data.map((c) => new Brand(c.id, c.image)),
      };
    }

    default:
      return state;
  }
};

export default BrandReducer;
