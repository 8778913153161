import CategoryCard from "../components/CategoryCard";
import WebLayout from "../layout/WebLayout";
import { useSubCategoryFetch } from "../hooks/useSubCategoryFetch";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

function CategoryPage() {
  const { categorySlug, subCategorySlug, childCategorySlug } = useParams();

  useSubCategoryFetch(categorySlug);

  return (
    <WebLayout pageName={CategoryPage}>
      <CategoryCard
        categorySlug={categorySlug}
        subCategorySlug={subCategorySlug}
        childCategorySlug={childCategorySlug}
      />
    </WebLayout>
  );
}

export default CategoryPage;
