import { BASE_URL } from "../../utils/Constants";
import { logout } from "./logout";

export const ADD_CART = "ADD_CART";

export const LIST_CART = "LIST_CART";

export const CART_COUNT = "CART_COUNT";

export const REMOVE_CART = "REMOVE_CART";

export const UPDATE_CART = "UPDATE_CART";

export const CART_CLEAN = "CART_CLEAN";

export const cartAdd = (product_id, color, size, variant) => {
  return async (dispatch) => {
    const response = await fetch(`${BASE_URL}cart/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        product_id,
        color,
        size,
        variant,
      }),
    });

    if (!response.ok) {
      const result = await response.json();
      throw new Error(result.msg);
    }

    const result = await response.json();

    if (result.msg === "Unauthenticated") {
      dispatch(logout());
    }

    if (result.status) {
      dispatch({
        type: ADD_CART,
      });
    } else {
      throw new Error(result.msg);
    }
  };
};

export const cartList = (storeCity = "") => {
  return async (dispatch) => {
    const response = await fetch(
      `${BASE_URL}cart/list/${window.btoa(storeCity)}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (!response.ok) {
      const result = await response.json();
      throw new Error(result.msg);
    }

    const result = await response.json();

    if (result.msg === "Unauthenticated") {
      dispatch(logout());
    }

    if (result.status) {
      dispatch({
        type: LIST_CART,
        data: {
          cart: result.data.cart,
          deliveryAmount: result.data.deliveryAmount,
          minimumOrderAmount: result.data.minimumOrderAmount,
          sellerId: result.data.sellerId,
        },
      });
    } else {
      throw new Error(result.msg);
    }
  };
};

export const cartRemove = (cartId, qty) => {
  return async (dispatch) => {
    // const token = getCookie(MSME_USER_TOKEN);
    // const parsedToken = JSON.parse(token);

    const response = await fetch(`${BASE_URL}cart/delete/${cartId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //Authorization: `Bearer ${parsedToken}`,
      },
    });

    if (!response.ok) {
      const result = await response.json();
      throw new Error(result.msg);
    }

    const result = await response.json();

    if (result.msg === "Unauthenticated") {
      dispatch(logout());
    }

    if (result.status) {
      dispatch({
        type: REMOVE_CART,
        cartId: cartId,
        qty: qty,
      });
    } else {
      throw new Error(result.msg);
    }
  };
};

export const cartUpdate = (cartId, productId, qty) => {
  return async (dispatch) => {
    // const token = getCookie(MSME_USER_TOKEN);
    // const parsedToken = JSON.parse(token);

    const response = await fetch(`${BASE_URL}cart/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        //Authorization: `Bearer ${parsedToken}`,
      },
      body: JSON.stringify({
        cart_id: cartId,
        product_id: productId,
        qty: qty,
      }),
    });

    if (!response.ok) {
      const result = await response.json();
      throw new Error(result.msg);
    }

    const result = await response.json();

    if (result.msg === "Unauthenticated") {
      dispatch(logout());
    }

    if (result.status) {
      dispatch({
        type: UPDATE_CART,
        data: {
          cart_id: cartId,
          product_id: productId,
          qty: qty,
        },
      });
    } else {
      throw new Error(result.msg);
    }
  };
};

export const cartCount = () => {
  return async (dispatch) => {
    // const token = getCookie(MSME_USER_TOKEN);
    // const parsedToken = JSON.parse(token);

    const response = await fetch(`${BASE_URL}cart/count`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //Authorization: `Bearer ${parsedToken}`,
      },
    });

    if (!response.ok) {
      const result = await response.json();
      throw new Error(result.msg);
    }

    const result = await response.json();

    if (result.msg === "Unauthenticated") {
      dispatch(logout());
    }

    if (result.status) {
      dispatch({
        type: CART_COUNT,
        data: result.data,
      });
    } else {
      throw new Error(result.msg);
    }
  };
};

export const cartClean = () => {
  return async (dispatch) => {
    // const token = getCookie(MSME_USER_TOKEN);
    // const parsedToken = JSON.parse(token);

    const response = await fetch(`${BASE_URL}cart/clean`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        //Authorization: `Bearer ${parsedToken}`,
      },
    });

    if (!response.ok) {
      const result = await response.json();
      throw new Error(result.msg);
    }

    const result = await response.json();

    if (result.msg === "Unauthenticated") {
      dispatch(logout());
    }

    if (result.status) {
      dispatch({
        type: CART_CLEAN,
      });
    } else {
      throw new Error(result.msg);
    }
  };
};
