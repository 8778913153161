import WebLayout from "../layout/WebLayout";
import toast from "react-hot-toast";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as authActions from "../store/action/auth";
import { FaInstagram, FaTwitter, FaLinkedin, FaYoutube } from "react-icons/fa";
import { toastMessage } from "../utils/Message";

function Registration() {
  const dispatch = useDispatch();

  const [isLoader, setIsLoader] = useState(false);

  const [name_of_entity, set_name_of_entity] = useState("");
  const [mobile, set_mobile] = useState("");
  const [email, set_email] = useState("");
  const [password, set_password] = useState("");
  const [referBy, setReferBy] = useState("ERM1");
  const [check, setCheck] = useState("Default");
  const [showPassword, setShowPassword] = useState(false);
  const page = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoader(true);
    try {
      const reg = await dispatch(
        authActions.registration(
          name_of_entity,
          mobile,
          email,
          password,
          referBy
        )
      );
      if (reg.status) {
        toastMessage(reg.msg, "success", "top-center", 2000);
        document.getElementById("regForm").reset();
        page("/welcome");
        setIsLoader(false);
      } else {
        toastMessage(reg.msg, "danger", "top-center", 5000);
      }
    } catch (e) {
      setIsLoader(false);
      toastMessage(e.message, "danger", "top-center", 5000);
    }
  };

  const handleRadioButtonChange = (e) => {
    setReferBy(e.target.value);
  };

  return (
    <WebLayout pageName={Registration}>
      <div className="container mx-auto px-4 mt-5">
        <div className="grid grid-cols-1 sm:grid-cols-6 gap-4">
          <div className="col-start-1 sm:col-start-2 col-span-4">
            <div className="reg-card">
              <div className="reg-header">
                <h2 className="text-xl font-bold text-center">
                  Registration Form – EMC
                </h2>
              </div>
              <div className="reg-body">
                <div className="">
                  <form onSubmit={handleSubmit} id="regForm">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                      <label className="block mb-1">
                        <span className="text-gray-700">
                          Name of Entity / Company
                          <span className="font-extrabold text-red-600">*</span>
                        </span>
                        <input
                          type="text"
                          name="name_of_entity"
                          id="name_of_entity"
                          value={name_of_entity}
                          onChange={(e) => set_name_of_entity(e.target.value)}
                          className="mt-1 block w-full form-control "
                          placeholder="Enter Name of Entity"
                          required={true}
                        />
                      </label>
                      <label className="block mb-1">
                        <span className="text-gray-700">
                          Mobile No.
                          <span className="font-extrabold text-red-600">*</span>
                        </span>
                        <input
                          type="text"
                          name="mobile"
                          value={mobile}
                          onChange={(e) =>
                            set_mobile(
                              e.target.value
                                .replace(/[^0-9.]/g, "")
                                .replace(/(\..*?)\..*/g, "$1")
                            )
                          }
                          className="mt-1 block w-full"
                          placeholder="Enter mobile No."
                          required={true}
                          maxLength={10}
                        />
                      </label>
                      <label className="block mb-1">
                        <span className="text-gray-700">
                          Email Id
                          <span className="font-extrabold text-red-600">*</span>
                        </span>
                        <input
                          type="email"
                          name="email"
                          value={email}
                          onChange={(e) => set_email(e.target.value)}
                          className="mt-1 block w-full"
                          placeholder="Enter Email Id"
                          required={true}
                        />
                      </label>
                      <label className="block mb-1">
                        <span className="text-gray-700">
                          Password
                          <span className="font-extrabold text-red-600">*</span>
                        </span>
                        <div className="relative rounded-md shadow-sm">
                          <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={password}
                            onChange={(e) => set_password(e.target.value)}
                            className="mt-1 block w-full"
                            placeholder="Enter Password"
                            required={true}
                          />
                          <div class="absolute inset-y-0 right-2 flex items-center">
                            <i
                              onClick={() => setShowPassword(!showPassword)}
                              className={`bx ${
                                showPassword ? "fa fa-eye" : "fa fa-eye-slash"
                              }`}
                              style={{ color: "#555" }}></i>
                          </div>
                        </div>
                      </label>
                      <label>
                        Do You Have Reference Id?{" "}
                        <input
                          style={{ marginRight: "10px", marginLeft: "10px" }}
                          type="radio"
                          value=""
                          checked={check === ""}
                          onChange={() => {
                            setCheck("");
                            setReferBy("");
                          }}
                        />
                        Yes
                        <input
                          style={{ marginRight: "10px", marginLeft: "10px" }}
                          type="radio"
                          value="Default"
                          checked={check === "Default"}
                          onChange={() => {
                            setCheck("Default");
                            setReferBy("ERM1");
                          }}
                        />
                        No
                      </label>
                      <div>
                        <label></label>
                        <label></label>
                      </div>

                      <label className="block mb-1">
                        <span className="text-gray-700">Referral ID</span>
                        <input
                          readOnly={check === "Default"}
                          type="text"
                          name="referral"
                          value={referBy}
                          onChange={(e) => setReferBy(e.target.value)}
                          className="mt-1 block w-full"
                          placeholder="Enter Referral ID"
                          style={{
                            backgroundColor:
                              check === "Default" ? "whitesmoke" : "white",
                          }}
                        />
                      </label>
                      <br />
                      <br />
                      <div className={"justify-between flex float-right"}>
                        <div className={"row"}>
                          <div className={"col-sm-12"}>
                            <button
                              type="submit"
                              className="bg-gray-500 text-white p-2 mt-4 rounded ">
                              {isLoader && (
                                <i className={"fa fa-spinner fa-spin"}></i>
                              )}
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="bg-indigo-500 ml-4 text-white p-2 mt-4 rounded ">
                              {isLoader && (
                                <i className={"fa fa-spinner fa-spin"}></i>
                              )}
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WebLayout>
  );
}

export default Registration;
