export class ProductDetails {
  constructor(
    id = "",
    name = "",
    productCode = "",
    barcode = "",
    mrp = "",
    selling_price = "",
    discount = "",
    pv = "",
    min_sale_qty = "",
    description = "",
    size = [],
    color = [],
    isVariant = "",
    productAvailability = false,
    cart = false,
    product_image = [],
    seller = "",
    selectedColor = "",
    selectedSize = "",
  ) {
    this.id = id;
    this.name = name;
    this.productCode = productCode;
    this.barcode = barcode;
    this.mrp = mrp;
    this.selling_price = selling_price;
    this.discount = discount;
    this.pv = pv;
    this.min_sale_qty = min_sale_qty;
    this.description = description;
    this.size = size;
    this.color = color;
    this.isVariant = isVariant;
    this.productAvailablility = productAvailability;
    this.cart = cart;
    this.product_image = product_image;
    this.seller = seller;
    this.selectedColor = selectedColor;
    this.selectedSize = selectedSize;
  }
}
