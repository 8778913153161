import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as productActions from "../store/action/product";

export const useCategoryProductsFetch = (type, city) => {
  const dispatch = useDispatch();
  const [categoryLoader, setCategoryLoader] = useState(false);

  const categoryProducts = useCallback(() => {
    (async () => {
      setCategoryLoader(true);
      try {
        await dispatch(productActions.getCategoryProducts(type, 14, city));
        setCategoryLoader(false);
      } catch (error) {
        setCategoryLoader(false);
      }
    })();
  }, [type, dispatch, city]);

  useEffect(() => {
    categoryProducts();
  }, [categoryProducts]);

  return [categoryLoader];
};
