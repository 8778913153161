import { GET_HOME_FAQ } from "../action/faq";
import { Faq } from "../../datamodels/Faq";
const initialState = {
  faq: [],
};

const FaqReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOME_FAQ: {
      const { data } = action;
      return {
        ...state,
        faq: data.map((c) => new Faq(c.id, c.question, c.answer)),
      };
    }

    default:
      return state;
  }
};

export default FaqReducer;
