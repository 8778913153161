import PageRoutes from "./routes/PageRoutes";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import { MSME_CITY, MSME_SEARCH, MSME_USER_TOKEN } from "./utils/Constants";
import { useDispatch } from "react-redux";
import * as authActions from "./store/action/auth";
import * as cartActions from "./store/action/cart";
import { getCookie } from "./utils/CookieAction";
import { ScrollToTop } from "./utils/Wrapper";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      const token = getCookie(MSME_USER_TOKEN);
      const city = localStorage.getItem(MSME_CITY);
      const search = localStorage.getItem(MSME_SEARCH);
      if (token) {
        const parsedToken = JSON.parse(token);
        dispatch(authActions.auth(parsedToken));
        await dispatch(cartActions.cartCount());
      } else {
      }

      if (city) {
        await dispatch(authActions.storeCity(city));
      }

      if (search) {
        await dispatch(authActions.storeSearch(search));
      }
    })();
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Toaster position="top-right" reverseOrder={false} />
      <ScrollToTop />
      <PageRoutes />
    </BrowserRouter>
  );
}

export default App;
