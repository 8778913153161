export const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export const offerResponsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export const categoryCarousel = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 12,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export const productCarousel = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export const colThree = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export const productData = [
  {
    inventory_id: 407388,
    canteen_id: 984,
    product_id: 93842,
    md_id: 17,
    stock: 5,
    mrp: "100.00",
    msp: "78.00",
    csp: "89.00",
    discount: "3.00",
    tax_rate: 18,
    mfg_date: "1970-01-01",
    expire_date: "2023-11-08",
    product_name: "Muskan Aata cookies 400gm",
    product_code: "18286001",
    canteen_code: "ASC999",
    barcode: "8906039864397",
    image: require("../assets/images/product/1.jpg"),
    brand_name: "Muskan",
    dp_price: "97.00",
    vendor_pv: "0.27",
    member_pv: "2.43",
  },
  {
    inventory_id: 407385,
    canteen_id: 984,
    product_id: 106493,
    md_id: 17,
    stock: 2,
    mrp: "170.00",
    msp: "90.84",
    csp: "130.42",
    discount: "8.50",
    tax_rate: 18,
    mfg_date: "2023-06-11",
    expire_date: "2024-12-10",
    product_name: "Ramkripa Rose Sharbat",
    product_code: "31411001",
    canteen_code: "ASC999",
    barcode: "8908007554002",
    image: require("../assets/images/product/2.jpg"),
    brand_name: "Ramkripa",
    dp_price: "161.50",
    vendor_pv: "0.93",
    member_pv: "8.41",
  },
  {
    inventory_id: 407384,
    canteen_id: 984,
    product_id: 87949,
    md_id: 17,
    stock: 2,
    mrp: "170.00",
    msp: "147.96",
    csp: "158.98",
    discount: "5.10",
    tax_rate: 18,
    mfg_date: "1970-01-01",
    expire_date: "2025-02-21",
    product_name: "Hamdard Roohafza 750ml",
    product_code: "21942001",
    canteen_code: "ASC999",
    barcode: "8901453000024",
    image: require("../assets/images/product/3.jpg"),
    brand_name: "Hamdard",
    dp_price: "164.90",
    vendor_pv: "0.27",
    member_pv: "2.47",
  },
  {
    inventory_id: 407383,
    canteen_id: 984,
    product_id: 93667,
    md_id: 17,
    stock: 10,
    mrp: "82.00",
    msp: "52.12",
    csp: "67.06",
    discount: "4.10",
    tax_rate: 5,
    mfg_date: "1970-01-01",
    expire_date: "2024-03-14",
    product_name: "Catch Meat Masala 100gm",
    product_code: "11923512",
    canteen_code: "ASC999",
    barcode: "8901192213112",
    image: require("../assets/images/product/4.jpg"),
    brand_name: "Catch Masala",
    dp_price: "77.90",
    vendor_pv: "0.42",
    member_pv: "3.78",
  },
  {
    inventory_id: 407382,
    canteen_id: 984,
    product_id: 87927,
    md_id: 17,
    stock: 9,
    mrp: "78.00",
    msp: "51.64",
    csp: "64.82",
    discount: "3.90",
    tax_rate: 5,
    mfg_date: "1970-01-01",
    expire_date: "2024-02-07",
    product_name: "Catch Black Pepper 50gm",
    product_code: "11923522",
    canteen_code: "ASC999",
    barcode: "8901192101013",
    image: require("../assets/images/product/5.jpg"),
    brand_name: "Catch Masala",
    dp_price: "74.10",
    vendor_pv: "0.37",
    member_pv: "3.33",
  },
  {
    inventory_id: 407381,
    canteen_id: 984,
    product_id: 93606,
    md_id: 17,
    stock: 20,
    mrp: "160.00",
    msp: "110.95",
    csp: "127.30",
    discount: "6.40",
    tax_rate: 5,
    mfg_date: "1970-01-01",
    expire_date: "2001-11-23",
    product_name: "Abhhyam moong dal chilka 1 kg",
    product_code: "11010065",
    canteen_code: "ASC999",
    barcode: "89061232708826",
    image: require("../assets/images/product/6.jpg"),
    brand_name: "AVI",
    dp_price: "153.60",
    vendor_pv: "0.69",
    member_pv: "6.21",
  },
  {
    inventory_id: 407380,
    canteen_id: 984,
    product_id: 114947,
    md_id: 17,
    stock: 7,
    mrp: "62.00",
    msp: "42.47",
    csp: "48.98",
    discount: "3.10",
    tax_rate: 5,
    mfg_date: "1970-01-01",
    expire_date: "2023-07-30",
    product_name: "Abhhyam Besan 500Gm",
    product_code: "11010049",
    canteen_code: "ASC999",
    barcode: "8906123270905",
    image: require("../assets/images/product/7.jpg"),
    brand_name: "AVI",
    dp_price: "58.90",
    vendor_pv: "0.27",
    member_pv: "2.47",
  },
  {
    inventory_id: 407379,
    canteen_id: 984,
    product_id: 88552,
    md_id: 17,
    stock: 50,
    mrp: "55.00",
    msp: "43.39",
    csp: "47.26",
    discount: "2.20",
    tax_rate: 5,
    mfg_date: "1970-01-01",
    expire_date: "2025-03-22",
    product_name: "Abhhyam sugar 1kg",
    product_code: "11010074",
    canteen_code: "ASC999",
    barcode: "89061232707768",
    image: require("../assets/images/product/8.jpg"),
    brand_name: "AVI",
    dp_price: "52.80",
    vendor_pv: "0.16",
    member_pv: "1.48",
  },
  {
    inventory_id: 406655,
    canteen_id: 984,
    product_id: 87922,
    md_id: 17,
    stock: 8,
    mrp: "125.00",
    msp: "97.84",
    csp: "106.43",
    discount: "3.75",
    tax_rate: 12,
    mfg_date: "1970-01-01",
    expire_date: "2024-08-15",
    product_name: "APIS Royal Zahidi Dates 250Gm + 250Gm Free",
    product_code: "28832017",
    canteen_code: "ASC999",
    barcode: "8906135092038",
    image: require("../assets/images/product/9.jpg"),
    brand_name: "Apis",
    dp_price: "121.25",
    vendor_pv: "0.36",
    member_pv: "3.20",
  },
  {
    inventory_id: 406654,
    canteen_id: 984,
    product_id: 87924,
    md_id: 17,
    stock: 5,
    mrp: "165.00",
    msp: "129.17",
    csp: "140.51",
    discount: "4.95",
    tax_rate: 12,
    mfg_date: "1970-01-01",
    expire_date: "2025-01-01",
    product_name: "APIS Himalaya Honey 225Gm + 250Gm Free",
    product_code: "28832015",
    canteen_code: "ASC999",
    barcode: "8906021922081",
    image: require("../assets/images/product/10.jpg"),
    brand_name: "Apis",
    dp_price: "160.05",
    vendor_pv: "0.47",
    member_pv: "4.22",
  },
];
