import WebLayout from "../layout/WebLayout";
import { FaRupeeSign } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import {
  defaultProductImage,
  MSME_USER_ADDRESS,
  MSME_USER_ORDER_DETAILS,
} from "../utils/Constants";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../store/action/auth";
import { useCartFetch } from "../hooks/useCartFetch";
import * as checkoutActions from "../store/action/checkout";

import { cleanExistAnce } from "../store/action/auth";
import { toastMessage } from "../utils/Message";
import BtnLoader from "../components/BtnLoader";

function Checkout() {
  const { accessToken, storeCity } = useSelector((state) => state.auth);
  const { cartList } = useSelector((state) => state.cart);

  useCartFetch(storeCity);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [qty, setQty] = useState(0);
  const [price, setPrice] = useState(0);
  const [tax, setTax] = useState(0);
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const [pv, setPV] = useState(0);
  const [mobileNumber, setMobileNumber] = useState("");
  const [name, setName] = useState("");
  const [referBy, setReferBy] = useState("");
  const [tabs, setTabs] = useState("login");
  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [loginToggle, setLoginToggle] = useState(true);
  const [registrationToggle, setRegistrationToggle] = useState(false);

  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const data = localStorage.getItem(MSME_USER_ORDER_DETAILS);
    if (data) {
      const parsedData = JSON.parse(data);
      setQty(parsedData.cartTotalQty);
      setPrice(parsedData.cartTotalPrice);
      setTax(parsedData.cartTax);
      setDeliveryCharges(parsedData.deliveryAmount);
      setPV(parsedData.pv);
    } else {
      navigate("/");
    }
  }, [navigate]);

  const checkout = (e) => {
    e.preventDefault();
    localStorage.setItem(
      MSME_USER_ADDRESS,
      JSON.stringify({
        name,
        email,
        state,
        address,
      }),
    );
    navigate("/payment", { replace: true });
  };

  const RegisterSubmit = async (e) => {
    e.preventDefault();
    setIsLoader(true);
    try {
      await dispatch(
        authActions.register(
          mobileNumber,
          name,
          referBy,
          password,
          email,
          state,
          address,
        ),
      );
      toast.success("Register Successfully");
      setName("");
      setMobileNumber("");
      setPassword("");
      setReferBy("");
      dispatch(cleanExistAnce());
      setIsLoader(false);
    } catch (error) {
      toast.error(error.message);
      setIsLoader(false);
    }
  };

  const loginSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(authActions.login(mobile, password));
      toast.success("Login Successfully");
      setMobile("");
      setPassword("");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const paymentDone = async (e) => {
    e.preventDefault();
    setIsLoader(true);
    try {
      await dispatch(checkoutActions.createOrder(storeCity));
      setIsLoader(false);
      toastMessage("Order Created Successfully", "success", "top-center", 2000);
      localStorage.removeItem(MSME_USER_ORDER_DETAILS);
      localStorage.removeItem(MSME_USER_ADDRESS);
      window.location.href =
        "https://app.amsmetrading.com/po-list/requested?type='BUYER'";
    } catch (e) {
      toastMessage(e.message, "danger", "top-center", 5000);
      setIsLoader(false);
    }
  };

  return (
    <WebLayout pageName={Checkout}>
      <div className="bg-gray-100">
        <div className="md:flex ">
          <div className="w-full p-4 px-4 py-4">
            <div className="md:grid md:grid-cols-3 gap-2 ">
              <div className="col-span-2 ">
                <div className="flex justify-between items-center p-2 bg-white shadow-md">
                  <p className="text-lg font-semibold leading-5 text-gray-800">
                    Login and Signup.{" "}
                    {accessToken ? (
                      <i className="fa fa-check icon-style"></i>
                    ) : (
                      ""
                    )}
                  </p>
                  <a
                    href="#"
                    className="text-white bg-blue-500 rounded-sm px-3 py-1"
                    onClick={() => setLoginToggle(!loginToggle || accessToken)}
                  >
                    <i
                      className={`${
                        loginToggle && !accessToken
                          ? "fa fa-angle-down"
                          : "fa fa-angle-right"
                      }`}
                    ></i>
                  </a>
                </div>

                {loginToggle && !accessToken && (
                  // <div className="flex w-full  flex-col justify-start items-start bg-white shadow-md p-2 mb-2">
                  //   <div className="flex-col justify-start items-start w-full grid grid-cols-2 gap-4">
                  //     <input
                  //       className="px-2 focus:outline-none focus:ring-2 focus:ring-gray-500 border-b border-gray-200 leading-4 text-base placeholder-gray-600 py-4 w-full"
                  //       type="text"
                  //       name="mobile"
                  //       placeholder="Enter Your Username"
                  //       required
                  //       value={mobile}
                  //       onChange={(e) => setMobile(e.target.value)}
                  //     />
                  //     <input
                  //       className="px-2 focus:outline-none focus:ring-2 focus:ring-gray-500 border-b border-gray-200 leading-4 text-base placeholder-gray-600 py-4 w-full"
                  //       type="password"
                  //       name="password"
                  //       placeholder="Enter Your Password"
                  //       value={password}
                  //       onChange={(e) => setPassword(e.target.value)}
                  //     />
                  //   </div>

                  //   <button
                  //     type={"submit"}
                  //     className="focus:outline-none focus:ring-blue-500 focus:ring-offset-2 mt-2 text-base font-medium focus:ring-2 focus:ring-ocus:ring-blue-800 leading-4 hover:bg-blue px-5 py-3  text-white bg-blue-500 rounded-sm">
                  //     Login
                  //   </button>
                  // </div>

                  <div className="grid grid-cols-2 text-center bg-white shadow-md p-2 mb-2">
                    <button
                      className={`font-semibold text-xl ${
                        tabs === "login"
                          ? "text-white bg-green-500 border-b border-green-500 p-2"
                          : "text-white bg-orange-500 border-b border-orange-500 p-2"
                      }`}
                      onClick={() => setTabs("login")}
                    >
                      Login
                    </button>
                    <button
                      className={`font-semibold text-xl ${
                        tabs === "register"
                          ? "text-white bg-green-500 border-b border-green-500 p-2"
                          : "text-white bg-orange-500 border-b border-orange-500 p-2"
                      }`}
                      onClick={() => setTabs("register")}
                    >
                      Sign Up
                    </button>
                    <div className="col-start-1 col-span-4">
                      <div className="py-4">
                        {tabs === "login" ? (
                          <form onSubmit={loginSubmit}>
                            <div className="grid grid-cols-1 gap-2">
                              <label className="relative block mb-1">
                                <span className="text-gray-700 sr-only">
                                  Mobile Number
                                </span>
                                <span className="absolute inset-y-0 left-1 flex items-center pr-3 border-r">
                                  <p className="h-6 w-6 fill-slate-300">+91</p>
                                </span>
                                <input
                                  type="text"
                                  name="mobile"
                                  className={`placeholder:text-black block h-12 border border-slate-300 rounded-md py-2 pl-12 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-md w-full`}
                                  placeholder="Mobile Number"
                                  required
                                  maxLength={10}
                                  value={mobile}
                                  onChange={(e) => setMobile(e.target.value)}
                                />
                              </label>

                              <label className="relative block mb-1 text-left">
                                <input
                                  type="password"
                                  name="password"
                                  className="placeholder:text-black block bg-white h-12 border border-slate-300 rounded-md py-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-md w-full"
                                  placeholder="Password"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  required
                                />
                              </label>
                            </div>

                            <button
                              disabled={isLoader}
                              className={`text-white text-lg w-full px-4 py-2 bg-indigo-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-500 dark:focus:ring-blue-600 inline-flex items-center justify-center mt-3 ${
                                isLoader ? "opacity-70" : ""
                              }`}
                            >
                              Login
                            </button>
                          </form>
                        ) : (
                          <form onSubmit={RegisterSubmit}>
                            <div className="grid grid-cols-2 gap-2">
                              <label className="relative block mb-1">
                                <span className="text-gray-700 sr-only">
                                  Mobile Number
                                </span>
                                <span className="absolute inset-y-0 left-1 flex items-center pr-3 border-r">
                                  <p className="h-6 w-6 fill-slate-300">+91</p>
                                </span>
                                <input
                                  type="text"
                                  name="mobile"
                                  className={`placeholder:text-black block h-12 border border-slate-300 rounded-md py-2 pl-12 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-md w-full`}
                                  placeholder="Mobile Number"
                                  required
                                  maxLength={10}
                                  value={mobileNumber}
                                  onChange={(e) =>
                                    setMobileNumber(e.target.value)
                                  }
                                />
                              </label>

                              <label className="relative block mb-1 text-left">
                                <input
                                  type="password"
                                  name="password"
                                  className="placeholder:text-black block bg-white h-12 border border-slate-300 rounded-md py-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-md w-full"
                                  placeholder="Password"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  required
                                />
                              </label>

                              <label className="block mb-1">
                                <input
                                  type="text"
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                  className="placeholder:text-black block bg-white h-12 border border-slate-300 rounded-md py-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-md w-full"
                                  placeholder="Enter Name"
                                  required={true}
                                />
                              </label>

                              <label className="block mb-1">
                                <input
                                  type="email"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  className="placeholder:text-black block bg-white h-12 border border-slate-300 rounded-md py-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-md w-full"
                                  placeholder="Enter Email"
                                  required={true}
                                />
                              </label>

                              <label className="block mb-1 text-left">
                                <input
                                  type="text"
                                  value={referBy}
                                  onChange={(e) => setReferBy(e.target.value)}
                                  className="placeholder:text-black block bg-white h-12 border border-slate-300 rounded-md py-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-md w-full"
                                  placeholder="Enter Referral Code (Optional)"
                                />
                              </label>
                              <label className="block mb-1 text-left">
                                <input
                                  type="text"
                                  value={state}
                                  onChange={(e) => setState(e.target.value)}
                                  className="placeholder:text-black block bg-white h-12 border border-slate-300 rounded-md py-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-md w-full"
                                  placeholder="Enter State"
                                />
                              </label>
                            </div>
                            <div className="grid grid-cols-1 w-full mt-2">
                              <textarea
                                className="px-2 focus:outline-none focus:ring-2 focus:ring-gray-500 border-b border-gray-200 leading-4 text-base placeholder-gray-600 py-4 w-full"
                                name="address"
                                placeholder="Address"
                                required
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                              ></textarea>
                            </div>
                            <button
                              disabled={isLoader}
                              type={"submit"}
                              className={`text-white text-lg w-full px-4 py-2 bg-indigo-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-500 dark:focus:ring-blue-600 inline-flex items-center justify-center mt-3 ${
                                isLoader ? "opacity-70" : ""
                              }`}
                            >
                              Register
                            </button>
                          </form>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <form onSubmit={paymentDone}>
                  <div className="flex justify-between items-center p-2 bg-white shadow-md mt-2">
                    <p className="text-lg font-semibold leading-5 text-gray-800">
                      Order Items
                    </p>
                    <a
                      href="#"
                      className="text-white bg-blue-500 rounded-sm px-3 py-1"
                      onClick={() =>
                        setRegistrationToggle(
                          !registrationToggle || !accessToken,
                        )
                      }
                    >
                      <i
                        className={`${
                          !registrationToggle && accessToken
                            ? "fa fa-angle-down"
                            : "fa fa-angle-right"
                        }`}
                      ></i>
                    </a>
                  </div>
                  {registrationToggle ||
                    (accessToken && (
                      <div className="flex w-full  flex-col justify-start items-start bg-white shadow-md p-2">
                        <div className="flex-col justify-start items-start w-full grid grid-cols-2 gap-4">
                          <div className="col-span-2 p-2 ">
                            {cartList.length > 0 &&
                              cartList.map((item) => {
                                return (
                                  <div
                                    key={item.id}
                                    className="flex justify-between items-center shadow-sm"
                                  >
                                    <div className="flex  p-2">
                                      <Link
                                        to={`/product-details/${item.product_slug}?product_id=${item.product_id}`}
                                      >
                                        <img
                                          src={item.product_image}
                                          className="rounded-sm aspect-[6/6] h-20 w-auto"
                                          alt="img"
                                          onError={(event) => {
                                            event.target.src =
                                              defaultProductImage;
                                            event.onerror = null;
                                          }}
                                        />
                                      </Link>
                                      <div className="flex flex-col ml-3">
                                        <Link
                                          to={`/product-details/${item.product_slug}?product_id=${item.product_id}`}
                                        >
                                          <span className="md:text-md font-medium">
                                            {item.product_name}
                                          </span>
                                        </Link>
                                        {item.color ? (
                                          <span className="text-xs font-light text-gray-400">
                                            Color : {item.color}
                                          </span>
                                        ) : (
                                          ""
                                        )}

                                        {item.size ? (
                                          <span className="text-xs font-light text-gray-400">
                                            Size : {item.size}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                    <div className="flex justify-center items-center">
                                      <div className="pr-10">
                                        Qty{" "}
                                        <input
                                          aria-label="input"
                                          className="border border-gray-300 h-7 text-center w-12 mr-2 ml-2"
                                          type="text"
                                          value={item.qty}
                                          readOnly
                                          id=""
                                        />
                                      </div>
                                      <div className="flex justify-center items-center pr-10">
                                        <FaRupeeSign />
                                        <span className="text-md font-medium">
                                          {item.totalPrice?.toFixed(2)}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                        <button
                          type={"submit"}
                          className={`text-white text-lg w-full px-4 py-2 bg-indigo-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-500 dark:focus:ring-blue-600 inline-flex items-center justify-center mt-3 ${
                            isLoader ? "cursor-not-allowed" : ""
                          }`}
                          disabled={isLoader}
                        >
                          {isLoader ? <BtnLoader /> : null}
                          Order Submit
                        </button>
                      </div>
                    ))}
                </form>
              </div>

              <div className="col-span-1 p-2 bg-white shadow-md">
                <h1 className="text-xl font-medium border-b px-2 pb-2">
                  Price Details
                </h1>
                <div className="flex justify-between items-center p-2">
                  <p className="text-md font-medium">Total Items</p>
                  <div className="flex justify-center items-center">
                    <span>{qty}</span>
                  </div>
                </div>
                <div className="flex justify-between items-center p-2">
                  <p className="text-md font-medium">Total PV</p>
                  <div className="flex justify-center items-center">
                    <span>{pv}</span>
                  </div>
                </div>
                <div className="flex justify-between items-center p-2">
                  <p className="text-md font-medium">Price</p>
                  <div className="flex justify-center items-center">
                    <FaRupeeSign />
                    <span>{(price - tax)?.toFixed(2)}</span>
                  </div>
                </div>

                <div className="flex justify-between items-center p-2">
                  <p className="text-md font-medium">Tax</p>
                  <div className="flex justify-center items-center">
                    + <FaRupeeSign />
                    <span>{tax?.toFixed(2)}</span>
                  </div>
                </div>

                <div className="flex justify-between items-center p-2">
                  <p className="text-md font-medium">Delivery Charges</p>
                  <div className="flex justify-center items-center">
                    + <FaRupeeSign />
                    <span>{deliveryCharges?.toFixed(2)}</span>
                  </div>
                </div>

                <div className="flex justify-between items-center p-2 border-t">
                  <p className="text-lg font-bold">Total Amount</p>
                  <div className="flex justify-center items-center">
                    <FaRupeeSign />
                    <span className="text-lg font-bold">
                      {(price + deliveryCharges)?.toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WebLayout>
  );
}

export default Checkout;
