import { Link } from "react-router-dom";
import WebLayout from "../layout/WebLayout";

function RegWelcome() {
  return (
    <WebLayout pagName={RegWelcome}>
      <div className="container mx-auto px-4 mt-5">
        <div className="grid grid-cols-6 gap-4">
          <div className="col-start-2 col-span-4">
            <div className="reg-card text-center py-10 px-20">
              <div className="m-auto">
                <img
                  src={require("../assets/logo.png")}
                  alt=""
                  className="text-center inline"
                  width={150}
                />
              </div>
              <h2 className="text-2xl font-bold mb-2">Congratulations 🎉</h2>
              <p className="text-xl font-bold mb-2">
                Welcome to abhhyamtrading.com
              </p>
              <p className="text-lg mb-4">
                Thank you for choosing to be a part of abhhyamtrading. Your
                registration is complete, and we're excited to have you on
                board. Get ready to explore, connect, and enjoy all the benefits
                of being a member. If you have any questions or need assistance,
                feel free to reach out.
              </p>
              <p className="text-xl font-bold mb-4">
                Helpline Number:- +91-9990060531, 9990060532
              </p>
              <Link
                to="/"
                className="bg-indigo-500 text-white p-2 mt-4 rounded">
                Go to Home
              </Link>

              <Link
                to="/"
                className="bg-indigo-500 text-white p-2 mt-4 ml-3 rounded">
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </WebLayout>
  );
}

export default RegWelcome;
