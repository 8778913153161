import { useState } from "react";

function FaqList({ index, question, answer }) {
  const [toggle, setToggle] = useState(false);

  return (
    <div className="shadow-md px-4 py-2" key={index}>
      <a
        href="#"
        className="flex items-center justify-between"
        onClick={() => setToggle(!toggle)}>
        <p className="font-normal text-base leading-6 text-gray-600 ">
          Q.{index + 1}) {question}
        </p>
        <button
          onClick={() => setToggle(!toggle)}
          class="rounded-md bg-indigo-600 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
          <i class={`${toggle ? "fa fa-angle-down" : "fa fa-angle-right"}`}></i>
        </button>
      </a>
      {toggle && (
        <div>
          <hr className="mt-1" />
          <p className="mt-1">Ans. {answer}</p>
        </div>
      )}
    </div>
  );
}

export default FaqList;
