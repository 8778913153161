import WebLayout from "../layout/WebLayout";
import { FaCartPlus } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import { useProductDetailsFetch } from "../hooks/useProductDetailsFetch";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import * as cartActions from "../store/action/cart";
import { toastMessage } from "../utils/Message";
import BtnLoader from "../components/BtnLoader";
import CityModal from "../components/modal/CityModal";

function ProductDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);

  const [cityModal, setCityModal] = useState(false);

  const startIndex = 0;
  const [previewImage, setPreviewImage] = useState("");
  const [type, setType] = useState("");

  const location = useLocation();
  const imagesToShow = 5;

  const searchParams = new URLSearchParams(location.search);
  const { productDetails } = useSelector((state) => state.product);
  const { storeCity } = useSelector((state) => state.auth);

  const [selectedSize, setSelectedSize] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [stockAvailability, setStockAvailability] = useState(false);

  const queryProductId = searchParams.get("product_id");

  const { slug } = useParams();

  useProductDetailsFetch(
    slug,
    queryProductId,
    selectedSize,
    selectedColor,
    type,
  );

  const visibleImages = productDetails.product_image.slice(
    startIndex,
    startIndex + imagesToShow,
  );

  const cartAdd = async () => {
    if (!storeCity) {
      setCityModal(true);
      return false;
    }
    setIsLoader(true);
    try {
      await dispatch(
        cartActions.cartAdd(
          productDetails.id,
          selectedColor,
          selectedSize,
          productDetails.isVariant,
        ),
      );
      setIsLoader(false);
      toastMessage(
        "Product successfully added to cart",
        "success",
        "top-center",
        2000,
      );
      navigate(`/seller-product-list/${productDetails.seller}`);
    } catch (e) {
      toastMessage(e.message, "danger", "top-center", 5000);
      setIsLoader(false);
    }
  };

  useEffect(() => {
    setSelectedColor("");
    setSelectedSize("");
    if (productDetails.product_image.length > 0) {
      setPreviewImage(productDetails.product_image[0].product_image);
    }
    if (productDetails?.selectedSize) {
      setSelectedSize(productDetails.selectedSize);
    }
    if (productDetails?.selectedColor) {
      setSelectedColor(productDetails.selectedColor);
    }
    if (productDetails.productAvailablility) {
      setStockAvailability(true);
    } else {
      setStockAvailability(false);
    }
  }, [
    productDetails.productAvailablility,
    productDetails.product_image,
    productDetails.selectedColor,
    productDetails.selectedSize,
  ]);

  return (
    <WebLayout pageName={ProductDetails}>
      <CityModal cityModal={cityModal} setCityModal={setCityModal} />
      <div className="bg-gray-100">
        <div className="lg:py-2 lg:px-4 md:py-2 md:px-4 py-2 px-4">
          <div className="flex gap-4 bg-white p-4 rounded-md mb-5">
            <div className=" w-full px-4 mb-8 md:w-1/3 md:mb-0">
              <div className="flex">
                <div>
                  {visibleImages.map((imageUrl, index) => (
                    <div className="p-2">
                      <img
                        key={index}
                        src={imageUrl.product_image}
                        alt={`Image ${index + 1}`}
                        className={
                          "block border border-gray-200 hover:border-orange-400 dark:border-gray-700 dark:hover:border-orange-300 w-14 h-14 object-contain p-2"
                        }
                        onClick={() => setPreviewImage(imageUrl.product_image)}
                      />
                    </div>
                  ))}
                </div>
                <div
                  className="relative mb-2"
                  style={{ width: "448px", height: "448px" }}
                >
                  <InnerImageZoom
                    src={previewImage}
                    zoomSrc={previewImage}
                    className="object-contain w-full lg:h-full"
                  />
                </div>
              </div>
              {productDetails.cart ? (
                <div className="flex flex-row justify-between border-b py-2">
                  <button
                    className="focus:outline-none focus:ring-2 hover:bg-green-500 focus:ring-offset-2 focus:ring-green-500 font-medium text-base leading-4 text-white  bg-green-500 inline-flex items-center py-3 px-4 rounded-sm w-full text-center justify-center"
                    onClick={() => navigate("/cart")}
                  >
                    <FaCartPlus /> <span className="pl-2">Go to Cart</span>
                  </button>
                </div>
              ) : (
                <div className="border-b py-2">
                  {stockAvailability ? (
                    <button
                      disabled={isLoader}
                      className={`focus:outline-none focus:ring-2 hover:bg-orange-500 focus:ring-offset-2 focus:ring-orange-500 font-medium text-base leading-4 text-white  bg-orange-500 inline-flex items-center py-3 px-4 rounded-sm w-full text-center justify-center ${
                        isLoader ? "opacity-70" : ""
                      }`}
                      onClick={() => cartAdd()}
                    >
                      {isLoader ? <BtnLoader /> : null}
                      <FaCartPlus /> <span className="pl-2">Add to Cart</span>
                    </button>
                  ) : (
                    <button
                      disabled={!stockAvailability}
                      className={`focus:outline-none focus:ring-2 hover:bg-orange-500 focus:ring-offset-2 focus:ring-orange-500 font-medium text-base leading-4 text-white  bg-orange-500 inline-flex items-center py-3 px-4 rounded-sm w-full text-center justify-center opacity-70`}
                    >
                      <FaCartPlus /> <span className="pl-2">Add to Cart</span>
                    </button>
                  )}
                </div>
              )}
            </div>
            <div className="w-full sm:w-96 md:w-8/12 lg:w-6/12 items-center">
              <h2 className="font-semibold lg:text-2xl text-xl lg:leading-9 leading-7 text-gray-800">
                {productDetails.name}
              </h2>

              {stockAvailability ? (
                <h6 className="font-semibold text-gray-400 text-xs">
                  Product code : {productDetails.productCode}
                </h6>
              ) : null}

              {stockAvailability ? (
                <div className={"flex space-x-4 items-center mt-2"}>
                  <p className="font-semibold text-2xl lg:leading-6 leading-5 mt-4 text-blue-500 italic">
                    <sup>₹</sup>
                    {productDetails.selling_price}
                  </p>

                  <p className="font-bold text-xs italic w-12 h-12 bg-green-600 text-white p-2 rounded-full flex justify-center items-center">
                    {productDetails.discount}%
                    <br />
                    Off
                  </p>
                </div>
              ) : null}
              {stockAvailability ? (
                <p className="font-semibold text-xl lg:leading-6 leading-5 mt-4 text-red-500 italic">
                  <del>MRP : ₹{productDetails.mrp}</del>
                </p>
              ) : null}

              <div className="mt-4">
                <div>
                  {productDetails.color.length > 0 ? (
                    <div className="mb-4 mt-2">
                      <div className={"flex items-center space-x-2"}>
                        <h2 className="text-sm font-bold text-gray-500">
                          Color
                        </h2>

                        <select
                          name=""
                          id=""
                          onChange={(e) => setSelectedColor(e.target.value)}
                        >
                          <option value="" selected disabled>
                            Select Colors
                          </option>
                          {productDetails.color.map((color, index) => {
                            return (
                              <option
                                key={index}
                                value={color}
                                selected={color === selectedColor}
                              >
                                {color}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  ) : null}
                </div>

                {productDetails.size.length > 0 ? (
                  <div className="pb-2 border-gray-300 dark:border-gray-700">
                    <div className={"flex items-center space-x-4"}>
                      <h2 className="text-sm font-bold text-gray-500">Size</h2>

                      <ul className="flex -space-x-px h-8 text-sm items-center my-5 gap-3">
                        {productDetails.size.map((size, i) => {
                          return (
                            <li key={i}>
                              <button
                                className={`px-3 h-12 leading-tight text-gray-500 border border-gray-300 hover:bg-orange-400 dark:border-gray-700 dark:hover:text-white hover:text-white cursor-pointer ${
                                  size === selectedSize
                                    ? "bg-orange-400 text-white"
                                    : "bg-white"
                                }`}
                                onClick={() => {
                                  setSelectedSize(size);
                                  setType("variants");
                                }}
                              >
                                {size}
                              </button>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                ) : null}
              </div>

              {!stockAvailability ? (
                <div
                  className={
                    "border-2 border-red-400 p-2 rounded text-red-500 mt-2 "
                  }
                >
                  This variant is not available yet.
                </div>
              ) : null}

              <p className="font-normal text-base leading-6 text-gray-600 mt-4">
                <div
                  dangerouslySetInnerHTML={{
                    __html: productDetails.description,
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </WebLayout>
  );
}

export default ProductDetails;
