import WebLayout from "../layout/WebLayout";
import { FaRupeeSign } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import PrimaryCartButton from "../components/button/PrimaryCartButton";
import { useCartFetch } from "../hooks/useCartFetch";
import { useSelector } from "react-redux";
import CartRemove from "../components/button/CartRemove";
import {
  defaultProductImage,
  MSME_USER_ORDER_DETAILS,
} from "../utils/Constants";

function Cart() {
  const navigate = useNavigate();
  const { cartList, deliveryAmount, minimumOrderAmount, sellerId } =
    useSelector((state) => state.cart);

  const { storeCity } = useSelector((state) => state.auth);

  useCartFetch(storeCity);

  let cartTax = 0;
  let cartTotalPrice = 0;
  let cartTotalQty = 0;
  let pv = 0;

  const placeOrder = () => {
    localStorage.setItem(
      MSME_USER_ORDER_DETAILS,
      JSON.stringify({
        cartTotalPrice,
        cartTotalQty,
        cartTax,
        deliveryAmount,
        pv,
      })
    );
    navigate("/checkout", { replace: true });
  };

  return (
    <WebLayout pageName={Cart}>
      <div className="bg-gray-100">
        <div className="md:flex ">
          <div className="w-full p-4 px-4 py-4">
            {cartList.length > 0 ? (
              <div className="md:grid md:grid-cols-3 gap-2 ">
                <div className="col-span-2 p-2 bg-white shadow-md">
                  <h1 className="text-xl font-medium border-b pb-2 justify-between flex">
                    View Cart Items
                    <p className={"font-bold text-red-500"}>
                      Minimum Order Amount : Rs. {minimumOrderAmount}
                    </p>
                    <Link
                      to={`/seller-product-list/${sellerId}`}
                      className={"font-bold text-blue-500"}>
                      View Seller Products
                    </Link>
                  </h1>
                  {cartList.length > 0 &&
                    cartList.map((item) => {
                      cartTotalPrice += item.sell_price * item.qty;
                      cartTotalQty += item.qty;
                      cartTax += item.tax * item.qty;
                      pv += item.pv * item.qty;
                      return (
                        <div
                          key={item.id}
                          className="flex justify-between items-center mt-5 shadow-sm">
                          <div className="flex  mb-2">
                            <img
                              src={item.product_image}
                              className="rounded-sm aspect-[6/6] h-20 w-auto"
                              alt="img"
                              onError={(event) => {
                                event.target.src = defaultProductImage;
                                event.onerror = null;
                              }}
                            />

                            <div className="flex flex-col ml-3">
                              <span className="md:text-md font-medium">
                                {item.product_name}
                              </span>

                              {item.color ? (
                                <span className="text-xs font-light text-gray-400">
                                  Color : {item.color}
                                </span>
                              ) : (
                                ""
                              )}

                              {item.size ? (
                                <span className="text-xs font-light text-gray-400">
                                  Size : {item.size}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="flex justify-center items-center">
                            <div className="flex justify-center items-center pr-10">
                              <FaRupeeSign />
                              <span className="text-md font-medium">
                                {item.totalPrice?.toFixed(2)}
                              </span>
                            </div>

                            <PrimaryCartButton
                              cartQty={item.qty}
                              minQty={item.min_sale_qty}
                              maxQty={item.max_sale_qty}
                              cartId={item.id}
                              productId={item.product_id}
                            />
                            <div className="pr-10">
                              <CartRemove cartId={item.id} qty={item.qty} />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>

                <div className="col-span-1 p-2 bg-white shadow-md h-68">
                  <h1 className="text-xl font-medium border-b px-2 pb-2">
                    Price Details
                  </h1>
                  <div className="flex justify-between items-center p-2">
                    <p className="text-md font-medium">Total Items</p>
                    <div className="flex justify-center items-center">
                      <span>{cartTotalQty}</span>
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-2">
                    <p className="text-md font-medium">PV</p>
                    <div className="flex justify-center items-center">
                      <span>{pv?.toFixed(2)}</span>
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-2">
                    <p className="text-md font-medium">Price</p>
                    <div className="flex justify-center items-center">
                      <FaRupeeSign />
                      <span>{(cartTotalPrice - cartTax)?.toFixed(2)}</span>
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-2">
                    <p className="text-md font-medium">Tax</p>
                    <div className="flex justify-center items-center">
                      + <FaRupeeSign />
                      <span>{cartTax?.toFixed(2)}</span>
                    </div>
                  </div>

                  <div className="flex justify-between items-center p-2">
                    <p className="text-md font-medium">Delivery Charges</p>
                    <div className="flex justify-center items-center">
                      + <FaRupeeSign />
                      <span>{deliveryAmount?.toFixed(2)}</span>
                    </div>
                  </div>

                  <div className="flex justify-between items-center p-2 border-t">
                    <p className="text-lg font-bold">Total Amount</p>
                    <div className="flex justify-center items-center">
                      <FaRupeeSign />
                      <span className="text-lg font-bold">
                        {(cartTotalPrice + deliveryAmount)?.toFixed(2)}
                      </span>
                    </div>
                  </div>
                  <button
                    className="bg-blue-500 rounded-sm text-white p-2 w-full"
                    onClick={placeOrder}>
                    Place Order
                  </button>
                </div>
              </div>
            ) : (
              <div className={"text-center"}>
                <div className="p-2 items-center justify-center flex">
                  <img
                    src={require("../assets/images/cart_empty.png")}
                    alt=""
                  />
                </div>
                <h1 className={"font-bold text-2xl"}>Your cart is empty</h1>
                <h3 className={"font-semibold text-gray-500"}>
                  Looks like you haven't added any product to your cart yet.
                </h3>
                <button
                  className="bg-blue-500 text-white py-2 px-4 mt-2 rounded-full"
                  onClick={() => navigate("/")}>
                  ADD PRODUCT TO CART
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </WebLayout>
  );
}

export default Cart;
