import { BASE_URL, MSME_USER_TOKEN } from "../../utils/Constants";
import { getCookie } from "../../utils/CookieAction";
import { logout } from "./logout";

export const CHECKOUT = "CHECKOUT";

export const CLEAR_CART = "CLEAR_CART";

export const createOrder = (city) => {
  return async (dispatch) => {
    const token = getCookie(MSME_USER_TOKEN);
    const parsedToken = JSON.parse(token);

    const fd = new FormData();
    fd.append("city", city);

    const response = await fetch(`${BASE_URL}checkout/create`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${parsedToken}`,
      },
      body: fd,
    });

    if (!response.ok) {
      const result = await response.json();
      throw new Error(result.msg);
    }

    const result = await response.json();

    if (result.msg === "Unauthenticated") {
      dispatch(logout());
    }

    if (result.status) {
      dispatch({
        type: CHECKOUT,
      });
      dispatch(clearCart());
    } else {
      throw new Error(result.msg);
    }
  };
};

export const clearCart = () => {
  return {
    type: CLEAR_CART,
  };
};
