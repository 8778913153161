import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as brandAction from "../store/action/brand";

export const useHomeBrandFetch = () => {
  const dispatch = useDispatch();

  const brandFetch = useCallback(() => {
    (async () => {
      try {
        await dispatch(brandAction.getHomeBrand());
      } catch (error) {}
    })();
  }, [dispatch]);

  useEffect(() => {
    brandFetch();
  }, [brandFetch]);

  return [];
};
