import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as categoryActions from "../store/action/category";

export const useCategoryFetch = () => {
  const dispatch = useDispatch();

  const categoryFetch = useCallback(() => {
    (async () => {
      try {
        await dispatch(categoryActions.getCategory());
      } catch (error) {}
    })();
  }, [dispatch]);

  useEffect(() => {
    categoryFetch();
  }, [categoryFetch]);

  return [];
};
