import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import BtnLoader from "../BtnLoader";
import { useNavigate } from "react-router-dom";
import { useCities } from "../../hooks/useCities";
import toast from "react-hot-toast";
import { MSME_CITY } from "../../utils/Constants";
import * as authActions from "../../store/action/auth";
import { toastMessage } from "../../utils/Message";
import Select from "react-select";

const CityModal = ({ cityModal, setCityModal }) => {
  const dispatch = useDispatch();
  const { city } = useSelector((state) => state.menu);
  const navigate = useNavigate();

  const [location, setLocation] = useState("");

  const data = city.map((c) => {
    return {
      label: c.name,
      value: c.name,
    };
  });

  useCities();

  const handleSearch = (e) => {
    e.preventDefault();
    if (location?.value?.length === 0) {
      toastMessage("Please Select City !", "danger", "top-center", 2000);
      return false;
    }

    if (location?.value) {
      localStorage.setItem(MSME_CITY, location?.value);
      dispatch(authActions.storeCity(location?.value));
    }

    navigate(`/search-product/${location?.value}/${window.btoa("search")}`);
  };

  return (
    <div>
      <Transition.Root show={cityModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setCityModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg h-72 items-center justify-center flex w-full">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 w-full">
                    <div className="mt-3 text-center">
                      <div className="mt-2">
                        <button
                          className="close_btn"
                          type="button"
                          onClick={() => setCityModal(false)}
                        >
                          <FaTimes />
                        </button>
                        <div className="container mx-auto px-12">
                          <div className="grid grid-cols-2 text-center">
                            <div className="col-start-1 col-span-4">
                              <div className={"py-4"}>
                                <form onSubmit={handleSearch}>
                                  <div className="grid grid-cols-1 gap-2">
                                    <Select
                                      value={location}
                                      onChange={setLocation}
                                      options={data}
                                      placeholder={"City"}
                                      className="custom-select"
                                      styles={{
                                        control: (provided, state) => ({
                                          ...provided,
                                          padding: 5,
                                          textAlign: "left",
                                        }),
                                        indicatorSeparator: (
                                          provided,
                                          state,
                                        ) => ({
                                          ...provided,
                                          width: 0,
                                        }),
                                        menu: (provided, state) => ({
                                          ...provided,
                                          maxHeight: "200px", // Set maximum height of the menu
                                        }),
                                        menuList: (provided, state) => ({
                                          ...provided,
                                          maxHeight: "200px", // Set maximum height of the menu list
                                        }),
                                      }}
                                    />
                                  </div>

                                  <button
                                    className={`text-white text-lg w-full px-4 py-2 bg-indigo-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-500 dark:focus:ring-blue-600 inline-flex items-center justify-center mt-3`}
                                  >
                                    Continue
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default CityModal;
