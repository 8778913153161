import React, { useState } from "react";
import { BsStarFill, BsStar } from "react-icons/bs";

export function Rating() {
  const [rating, setRating] = useState(0);

  const handleRating = (rate) => {
    setRating(rate);
  };

  return (
    <div className="flex mt-2 gap-1">
      {rating >= 1 ? (
        <BsStarFill onClick={() => handleRating(1)} color="orange" />
      ) : (
        <BsStar onClick={() => handleRating(1)} />
      )}
      {rating >= 2 ? (
        <BsStarFill onClick={() => handleRating(2)} color="orange" />
      ) : (
        <BsStar onClick={() => handleRating(2)} />
      )}
      {rating >= 3 ? (
        <BsStarFill onClick={() => handleRating(3)} color="orange" />
      ) : (
        <BsStar onClick={() => handleRating(3)} />
      )}
      {rating >= 4 ? (
        <BsStarFill onClick={() => handleRating(4)} color="orange" />
      ) : (
        <BsStar onClick={() => handleRating(4)} />
      )}
      {rating >= 5 ? (
        <BsStarFill onClick={() => handleRating(5)} color="orange" />
      ) : (
        <BsStar onClick={() => handleRating(5)} />
      )}
    </div>
  );
}
