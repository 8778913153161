import { GET_HOME_BANNERS } from "../action/banner";
import { Banner } from "../../datamodels/Banner";
const initialState = {
  banners: [],
};

const BannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOME_BANNERS: {
      const { data } = action;
      return {
        ...state,
        banners: data.map((c) => new Banner(c.id, c.image)),
      };
    }

    default:
      return state;
  }
};

export default BannerReducer;
