import { BASE_URL } from "../../utils/Constants";

export const GET_CATEGORY = "GET_CATEGORY";

export const GET_SUB_CATEGORY = "GET_SUB_CATEGORY";

export const GET_SUB_AND_CHILD_CATEGORY = "GET_SUB_AND_CHILD_CATEGORY";

export const getCategory = () => {
  return async (dispatch) => {
    const response = await fetch(`${BASE_URL}category-list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const result = await response.json();
      throw new Error(result.message);
    }

    const result = await response.json();

    if (result.status) {
      dispatch({
        type: GET_CATEGORY,
        data: result.data,
      });
    } else {
      throw new Error(result.message);
    }
  };
};

export const getSubCategory = (id) => {
  return async (dispatch) => {
    const response = await fetch(`${BASE_URL}sub-category/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const result = await response.json();
      throw new Error(result.message);
    }

    const result = await response.json();

    if (result.status) {
      dispatch({
        type: GET_SUB_CATEGORY,
        data: result.data,
      });
    } else {
      throw new Error(result.message);
    }
  };
};

export const getSubAndChildCategory = (slug_url) => {
  return async (dispatch) => {
    const response = await fetch(
      `${BASE_URL}sub_category_and_child_category/${slug_url}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (!response.ok) {
      const result = await response.json();
      throw new Error(result.message);
    }

    const result = await response.json();

    if (result.status) {
      dispatch({
        type: GET_SUB_AND_CHILD_CATEGORY,
        data: result.data,
      });
    } else {
      throw new Error(result.message);
    }
  };
};
